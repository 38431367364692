import React, { Component } from "react";
import { Grid, Box, Container, Button } from "@material-ui/core";
import "./intendedUnitSelector.css";

class IntendedUnitSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBuilding: this.props.buildings[this.props.selectedBuildingIndex]
        .data.name,
      // selectedUnit: this.props.Units.filter(unit=>unit.data.belongsTo.id===this.props.buildings[this.props.selectedBuildingIndex].id)[0].data.name,
      selectedUnit: "",
      unitsBelongingToSelectedBuilding: this.props.Units.filter(
        (unit) =>
          unit.data.belongsTo.id ===
            this.props.buildings[this.props.selectedBuildingIndex].id &&
          !unit.data.isWholeBuilding
      ),
    };
  }
  componentDidMount() {
    let name = "";
    const filter = this.props.Units.filter(
      (unit) =>
        unit.data.belongsTo.id ===
        this.props.buildings[this.props.selectedBuildingIndex].id
    );
    if (filter.length) {
      name = filter[0].data.name;
      this.setState({ selectedUnit: name });
    } else {
      this.setState({ selectedUnit: "nothin" });
    }
  }
  handleChange = (e) => {
    // console.log('changed')

    var value = e.target.value;
    // console.log(value, 'this is what is returned on as the target value on the event obj')
    this.setState({ selectedBuilding: e.target.value });
    // console.log('here you should also change the value of state of selectedUnit to be the first at the top of the new group of units    ')
    let currentBuilding = this.props.allBuildings.find(
      (building) => building.data.name === value
    );
    // console.log(currentBuilding,'currentBuilding')
    let currentUnits = this.props.Units.filter(
      (unit) =>
        unit.data.belongsTo.id === currentBuilding.id &&
        !unit.data.isWholeBuilding
    );
    // console.log(currentUnits, 'the currentUnits')
    this.setState({ unitsBelongingToSelectedBuilding: currentUnits });
    let nameOfUnitOnTopOfSelectBox;
    if (currentUnits[0]) {
      nameOfUnitOnTopOfSelectBox = currentUnits[0].data.name;
    } else {
      nameOfUnitOnTopOfSelectBox = "no unit";
    }
    // console.log(nameOfUnitOnTopOfSelectBox, 'this is the unit at the topo of the select box in the new group of units when the building select was changed')
    this.setState({ selectedUnit: nameOfUnitOnTopOfSelectBox });
  };

  handleSelectedUnit = (e) => {
    // console.log('unit was selected')
    // var value=e.target.value;
    // console.log(value, 'this is what is returned on as the target value on the event obj')
    this.setState({ selectedUnit: e.target.value });
  };

  handleIntendedUnitSubmission = () => {
    this.props.closeModal();
    this.props.setSelectedUnitName(this.state.selectedUnit);
    // let somevariable= this.props.buildings.find(building=>building.data.name===this.state.selectedBuilding)

    let index = this.props.buildings
      .map(function (x) {
        return x.data.name;
      })
      .indexOf(this.state.selectedBuilding);
    console.log(index, "index");
    this.props.onSelectBuildingIndex(index);
  };

  handleLeasedBuilding = (unitObj) => {
    if (!unitObj) {
      return "there are no offices or warehouses for lease in this building it is either fully leased, or being renovated. pick another building.";
    } else {
      return `The Selected Unit is ${this.state.selectedUnit}, The unit's Size is listed at ${unitObj.data.size} Square Feet Leasing Monthly at ${unitObj.data.price}`;
    }
  };

  render() {
    // console.log(this.props, 'the props')
    console.log(
      this.state.unitsBelongingToSelectedBuilding,
      "the unitsbelongingto selected building you must filter here out the ones"
    );

    const {
      allBuildings,
      //  Units
    } = this.props;
    // const specificBuilding= allBuildings.filter(building=>building.data.name===this.state.selectedBuilding)
    let selectedUnitObj = this.state.unitsBelongingToSelectedBuilding.find(
      (unit) => unit.data.name === this.state.selectedUnit
    );
    // console.log(selectedUnitObj, 'this is the selectedUnit Object')
    return (
      <div
        data-tut=""
        // className={"inline-block relative w-64"}
        className={null}
      >
        <Container spacing={3} style={{ marginBottom: 10 }}>
          <div className={"dual-select"}>
            <select
              value={this.state.selectedBuilding}
              onChange={this.handleChange}
              className={
                "block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              }
            >
              {allBuildings.map((building) => (
                <option key={building.id}>{building.data.name}</option>
              ))}
            </select>

            <select
              value={this.state.selectedUnit}
              onChange={this.handleSelectedUnit}
              className={
                "block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              }
            >
              {/* {Units.filter(unit=>unit.data.belongsTo.id==specificBuilding[0].id).map(unit=><option key={unit.id}>{unit.data.name}</option>)} */}{" "}
              */}
              {this.state.unitsBelongingToSelectedBuilding.map((unit) => (
                <option key={unit.id}>{unit.data.name}</option>
              ))}
            </select>
          </div>

          <Box display="flex" justifyContent="center" m={1} p={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} className="selected-unit-modal-description">
                The Selected Building is {this.state.selectedBuilding},{" "}
                {this.handleLeasedBuilding(selectedUnitObj)}
              </Grid>
            </Grid>
          </Box>

          <Button
            fullWidth
            className="bule-btn"
            onClick={this.handleIntendedUnitSubmission}
          >
            Select Unit
          </Button>
        </Container>
      </div>
    );
  }
}

export default IntendedUnitSelector;
