import React, { Component } from "react";
import "./CardStyles.css";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import ImageLoader from "./ImageLoader/index";
// import ProgressiveImage from "react-progressive-image";
// import { Defer, Img } from 'react-progressive-loader'
import ProgressiveImage from "react-progressive-image-loading";
// import { storage } from "../../firebase";

class Card extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // value: this.props.cardLink.params.id
    };
  }
  getBlur = (path) => {
    path = decodeURIComponent(path);
    path = path.substring(path.lastIndexOf("/") + 1);
    return (path.match(/[^.]+(\.[^?#]+)?/) || [])[0];
  };
  // generateSmallBlur = () => {
  //   console.log('its my job to generate the small blur...')
  //   let fileName = this.props.fileUrl.substring(this.props.fileUrl.indexOf("images%2"), this.props.fileUrl.indexOf(".jpg?alt="));
  //   let cutfile = fileName.slice(9)
  //   let smallBlur = cutfile + "_10x8.jpg"
  //   console.log(smallBlur, 'smallBlur')
  //   storage.ref('images').child(smallBlur).getDownloadURL().then(url => {
  //     if(url){
  //       return url
  //     }
  //   }).catch((err) => console.log(err,'the image was uploaded before resize image plugin was installed'))
  // }
  render() {
    // console.log(this.props, "this.props from card component render");
    // console.log('units from card', this.props.totalUnits)
    let squarefootageArray = [];
    // console.log('belongs to:',this.props.totalUnits.filter(x=>x.data.belongsTo.id==this.props.newLink).length)
    const unitsInCard = this.props.totalUnits.filter(
      (x) =>
        x.data.belongsTo.id === this.props.newLink && !x.data.isWholeBuilding
    );
    // console.log(unitsInCard, "= unitsInCard")
    const length = unitsInCard.length;

    unitsInCard.map((x) => squarefootageArray.push(x.data.size));
    // console.log('squarefootageArray length', squarefootageArray.length);
    //console.log('unitsInCard', unitsInCard)
    let min = Math.min(...squarefootageArray);
    let max = Math.max(...squarefootageArray);
    if (squarefootageArray.length === 0) {
      min = "0";
      max = "0";
    }

    let pricesholder = [];

    unitsInCard.map((x) => pricesholder.push(x.data.price));
    let squarefootageArrayCopy = [...squarefootageArray];
    let pricesPerSquareFoot = [];
    for (let i = 0; i < pricesholder.length; i++) {
      if (pricesholder[i] !== 0) {
        pricesPerSquareFoot.push(
          Number(pricesholder[i] / squarefootageArrayCopy[i]) * 12
        );
      }
    }
    let minPPSQFT = Math.min(...pricesPerSquareFoot).toFixed(2);
    let maxPPSQFT = Math.max(...pricesPerSquareFoot).toFixed(2);
    if (minPPSQFT === "Infinity" || maxPPSQFT === "Infinity") {
      minPPSQFT = "0";
      maxPPSQFT = "0";
    }

    const returnDesc = (desc, length) => {
      if (desc.length > length) {
        let trimmedString = desc.substr(0, length);
        return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + "..."
      } return desc
    }
    //console.log('props newlink poiqurp',this.props.newLink)
    return (
      <TransitionGroup className="card-container">
        <CSSTransition
          key={this.props.newLink}
          timeout={4500}
          classNames="slide"
        >
          <div className="col s12 m7" data-tut="reactour__iso" id="cardest">
            {/* <Link onClick={()=>console.log(this.props.newLink)} to={`/buildings/${this.props.newLink}/:slug`}> */}
            <Link
              onClick={() =>
                console.log(this.props.newLink, "this.props.newLink")
              }
              to={`/buildings/${this.props.newLink}/${this.props.name
                .split(" ")
                .join("_")}`}
            >
              <div className="card building-card horizontal">
                <div className="card-image">
                  {/* this is where you set the iniial state of imageloaded to false becuase its not loaded, and show a pinner or 
              loading component until it is loaded.. */}
                  {/* <img id='window_Image' src={process.env.PUBLIC_URL+"/assets/building_photos/"+this.props.newLink+".jpg"}/> */}

                  {/* <ImageLoader newLink={this.props.newLink}/> */}
                  {/* 
           <ProgressiveImage src={process.env.PUBLIC_URL+"/assets/building_photos/"+this.props.newLink+".jpg"} placeholder={process.env.PUBLIC_URL+"/assets/building_photos/36L8HqrmCtVmerTNfTdh_low.jpg"}>
           {src => <img src={src} alt="an image" />}
            </ProgressiveImage> */}

                  {/* <Img
            bgColor='#FA8054'
  src={process.env.PUBLIC_URL+"/assets/building_photos/"+this.props.newLink+".jpg"}
  
/> */}

                  <div id="card-image-container">
                    <ProgressiveImage
                      preview={
                        process.env.PUBLIC_URL +
                        "/assets/building_photos/tinyBlur.jpg"
                      }
                      // src={process.env.PUBLIC_URL+"/assets/building_photos/"+this.props.newLink+".jpg"}
                      // preview={""
                      // process.env.PUBLIC_URL +
                      // "/assets/building_photos/smallblur.jpg"
                      // this.generateSmallBlur()
                      // }
                      // preview={process.env.PUBLIC_URL+"/assets/building_photos/blurs"+this.getBlur(this.props.fileUrl)}

                      src={
                        // process.env.PUBLIC_URL +
                        // "/assets/building_photos/" +
                        // this.props.newLink +
                        // ".jpg"
                        this.props.fileUrl
                      }
                      transitionTime={4000}
                      transitionFunction="ease"
                      render={(src, style) => (
                        <img id="sizeIt" alt="" src={src} style={style} />
                      )}
                    />
                  </div>

                  <span id="window_Feature" className="card-badge orange">
                    {this.props.name}
                  </span>
                </div>
                <div className="card-stacked">
                  <div className="card-content">
                    {/* $11.00 - $13.00 SF/YR */}
                    <div id="window_Content">
                      <p className="cardTitle">
                        {minPPSQFT}${" "}
                        {minPPSQFT === maxPPSQFT ? "" : "-" + maxPPSQFT + "$"}{" "}
                        SF/YR
                      </p>
                      <p className="card-description">
                        {returnDesc(this.props.description, 140)}
                      </p>
                    </div>
                  </div>
                  <div className="card-action">
                    <span
                      id="sqfootage"
                      style={{ float: "left", marginRight: "0px" }}
                    >
                      {min}
                      {max === min ? "" : "-"}
                      {max === min ? "" : max}
                      <sup style={{ color: "black" }}>SQFT</sup>
                    </span>
                    <span className="new badge red" data-badge-caption="built">
                      {this.props.yearBuilt}
                    </span>
                    <span className="new badge blue" data-badge-caption="units">
                      {length}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default Card; // preview={"https://via.placeholder.com/100"}
