import React, { Component } from "react";
import Modal from "react-modal";
import SignInScreen from "../SingInScreen/SignInScreen";
import Logo from "./Logo.jsx";
import { firebase } from "../../firebase";
import "./Header.css";
// import Tour from "reactour";
import TourComponent from "./TourComponent";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
// import { Redirect } from "react-router-dom";
import GenerateCouponForm from "../Map/generateCoupon";
import {
  FaWarehouse,
  FaStreetView,
  FaCalendarPlus,
  // FaInfoCircle,
} from "react-icons/fa";

// import GeoLocationIcon from "../GeolocationIcon/Icon"

// import {
//   // MdNavigation
//   FaMapMarkedAlt,
//   FaLocationArrow
// } from "react-icons/fa";

import {
  // MdNavigation
  MdMyLocation
} from "react-icons/md";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};

const CouponStyle = {
  content: {
    top: "100%",
  },
};

const buildingSelectorStyles = {
  content: {
    top: "30%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
  },
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedBuilding: "",
      selectedBuildingIndex: 0,
      selectedBuilding: this.props.allBuildings
        ? this.props.allBuildings[this.props.selectedBuildingIndex].data.name
        : "",
      generateCouponIsOpen: false,
      // togglerIsOpen: false,
      modalIsOpen: false,
      isShowingMore: false,
      isTourOpen: false,
    };
    this.openCouponGenerator = this.openCouponGenerator.bind(this);
    this.closeCouponGenerator = this.closeCouponGenerator.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    // this.openDirectionsToggler = this.openDirectionsToggler.bind(this)
    // this.closeDirectionsToggler = this.closeDirectionsToggler.bind(this)
    this.handleBuildingChange = this.handleBuildingChange.bind(this);
  }

  openCouponGenerator() {
    this.setState({ generateCouponIsOpen: true });
  }

  closeCouponGenerator() {
    this.setState({ generateCouponIsOpen: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  // openDirectionsToggler() {
  //   this.setState({ togglerIsOpen: true })
  // }

  // closeDirectionsToggler() {
  //   this.setState({ togglerIsOpen: false })
  // }

  handleBuildingChange(e) {
    var value = e.target.value;
    console.log(value, "value of the selected building");
    this.setState({ selectedBuilding: value });
    let allo = this.props.allBuildings;
    console.log(allo, "allo");
    let building = this.props.allBuildings.find((x) => x.id === value);
    console.log(building, "building data");
    for (var i = 0; i < allo.length; i++) {
      if (allo[i].id === value) {
        this.props.onSelectBuildingIndex(i);
        return this.props.closeDirectionsToggler();
      }
    }
  }

  componentDidMount() {
    if (firebase) {
      // console.log("firebase is");
    } else {
      // console.log("firebase is not");
    }
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      this.setState({ modalIsOpen: false });
    });

    this.setState({ selectedBuildingIndex: this.props.selectedBuildingIndex });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openTour = () => {
    this.setState({ isTourOpen: true });
  };
  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  setToCard = () => {
    let path = this.props.history.location.pathname;
    if (path === "/addBuilding") {
      this.props.history.push("/addBuilding");
    } else {
      this.props.history.push("/warehouse/8111_St_Louis");
    }
  };

  render() {
    // this.props.buildings[this.props.selectedBuildingIndex].data.name
    // console.log(this.props, 'props render header it is or it is not?')
    // console.log('render method called in header')
    // const { match, location, history } = this.props;
    // const { isTourOpen, isShowingMore, steps } = this.state;
    let signedin = this.props.in;
    let pathadress = this.props.location.pathname;
    // console.log("pathadress", pathadress);
    // console.log('header"s props:');
    // console.log(this.props);
    let pathArray = pathadress.split("/");
    console.log(pathArray[1], "pathArray");
    return (
      <>
        {this.props.tourNeeded ? (
          //   <Tour
          //     // update={location.pathname}
          //     steps={steps}
          //     isOpen={this.state.isTourOpen}
          //     onAfterOpen={this.disableBody}
          //     onBeforeClose={this.enableBody}
          //     maskClassName="mask"
          //     className="helper"
          //     rounded={5}
          //     onRequestClose={this.closeTour}
          //   />

          <TourComponent
            isOpen={this.state.isTourOpen}
            onRequestClose={this.closeTour}
            {...this.props}
          />
        ) : null}

        <nav className="light-blue lighten-1" role="navigation">
          <div className="nav-wrapper ">
            <Logo
              onClick={() => this.setToCard()}
              alt="logo"
              height="42"
              width="42"
              {...this.props}
            />

            <ul className="right ">
              {/* <Link to={`/`} className="directionsIcon" style={{fontSize:'1.1rem'}} onClick={this.props.directions}>Directions</Link> */}

              {signedin &&

                pathadress !== "/addBuilding" && (
                  <li>
                    <Link
                      to={`/addBuilding`}
                      id="Add-building-map"
                      className="tourIcon"
                    >
                      Add
                    </Link>
                  </li>
                )}

              {pathadress === "/addBuilding" && this.props.in && (
                <li>
                  <div className="tourIcon" onClick={this.openCouponGenerator}>
                    Coupon
                  </div>
                </li>
              )}

              {(pathadress === "/addBuilding") && (
                <li>
                  <Link
                    to={`/`}
                    id="goBackhome"
                    className="tourIcon"
                  >
                    Home
                  </Link>
                </li>
              )}
              {pathadress === "/addBuilding" && (
                <li>
                  <Link
                    to={`/addBuilding`}
                    id="goBackhome"
                    className="tourIcon"
                  >
                    ADD
                  </Link>
                </li>
              )}

              {((this.props.tourNeeded && !signedin) ||
                (pathArray[1] === "buildings" && pathArray[2])) && (
                  <li>
                    <div
                      className="tourIcon"
                      onClick={this.openTour}
                    >
                      {/* <FaInfoCircle
                        alt="warehouses page"
                        style={{
                          display: "initial",
                          fontSize: "x-large",
                          marginBottom: "2px",
                          marginRight: "4px",
                          color: "white",
                        }}
                      /> */}
                      <span id="tourText"
                      >Tour</span>
                    </div>
                  </li>
                )}

              {(pathadress === "/directions" || pathadress === "/buildings") && (
                <li>
                  <Link
                    to={`/calendar`}
                    id="calendarLink"
                    className="tourIcon"
                  >
                    <FaCalendarPlus
                      id="calendarsvg"
                      alt="warehouses page"
                      style={{
                        display: "initial",
                        fontSize: "x-large",
                        marginBottom: "2px",
                        marginRight: "4px",
                        color: "white",
                      }}
                    />
                    Book
                  </Link>
                </li>
              )}

              {/* {pathadress === "/directions" && (
                <li>
        
                    <button onClick={()=>{this.openDirectionsToggler()}}>change</button>
                </li>
              )} */}

              {
                pathadress !== "/addBuilding" &&
                  pathadress !== "/buildings" ? (
                    <li>
                      <Link
                        to={`/directions`}
                        onClick={
                          pathadress === "/directions"
                            ? this.props.openDirectionsToggler
                            : this.props.directions
                        }
                        className="tourIcon"
                        id="directionslink"
                      >
                        <div className="navigation-icon">
                          {/* <FaMapMarkedAlt style={{ display: "initial", fontSize: "large", marginBottom: "7px", marginRight: "8px", color: "silver" }} /> */}
                          {/* <FaLocationArrow style={{ display: "initial", fontSize: "normal", marginBottom: "2px", marginRight: "0px", color: "white" }}/> */}
                          <MdMyLocation
                            style={{
                              display: "initial",
                              fontSize: "x-large",
                              marginBottom: "2px",
                              marginRight: "0px",
                              color: "white",
                            }}
                          />
                          {/* <GeoLocationIcon style={{ display: "initial"}}/> */}<span id="show-large">{" Get "}</span>
                          <span id="show-medium">{"Driving "}</span>
                          <span id="show-small">{"Directions to "}</span>
                          <span>{" "}</span>
                          <span style={{ fontStyle: "normal" }}>
                            {this.props.addressNumberArray &&
                              (this.props.selectedBuildingIndex ||
                                this.props.selectedBuildingIndex === 0) &&
                              this.props.addressNumberArray[
                              this.props.selectedBuildingIndex
                              ]}
                          </span>
                        </div>
                        {/* Directions{" "} */}
                      </Link>
                    </li>
                  ) : // <li>
                  //   <Link style={{ fontSize: "1.1rem" }} to={`/calendar`}>
                  //     Schedule
                  //   </Link>
                  // </li>
                  null}

              {(this.props.location.pathname === "/" || pathArray[1] === 'warehouse') ? (
                <li>
                  <div
                    id="hideStreet"
                    className="tourIcon"
                    // style={{ fontSize: "1.1rem" }}
                    onClick={this.props.toggleStreet}
                  >
                    {/* {this.props.isHidden ? "Hide" : <span>Street<span id="hideSmall">View</span></span>} */}
                    {this.props.isHidden ? (
                      <span id="hide-street-text">Hide</span>
                    ) : (
                        <>
                          <FaStreetView
                            alt="Street view of the building"
                            style={{
                              display: "initial",
                              fontSize: "xxx-large",
                              // marginBottom: "2px",
                              // marginRight: "3px",
                              color: "#2d5575",
                            }}
                          />
                          {/* <span
                            className="hideSmall"
                            style={{ fontStyle: "normal" }}
                          >
                            Street View
                        </span> */}
                        </>
                      )}
                  </div>
                </li>
              ) : null}

              <li>
                <Link
                  to={`/buildings`}
                  className="tourIcon"
                  id="buildingsPageLink"
                >
                  <div className="navigation-icon">
                    <FaWarehouse
                      alt="warehouses page"
                      style={{
                        display: "initial",
                        fontSize: "x-large",
                        marginBottom: "2px",
                        marginRight: "4px",
                        color: "white",
                      }}
                    />
                    <span style={{ fontStyle: "" }}>
                      Buildings
                    </span>
                  </div>
                </Link>
              </li>

              {this.props.in || this.props.inUnauthorized ? (
                <li>
                  <div
                    id="signOut-button"
                    onClick={() => firebase.auth().signOut()}
                    className="waves-effect waves-light btn login-logout-btn login-button-margins logout-smallscreen-margins"
                  >
                    Exit
                  </div>
                </li>
              ) : (
                  <li>
                    <div
                      id="signIn-button"
                      onClick={this.openModal}
                      className="waves-effect waves-light btn login-logout-btn login-button-margins"
                    >
                      SignIn
                  </div>
                  </li>
                )}
            </ul>
          </div>
        </nav>

        <Modal
          // className="bg-white shadow-md rounded px-8 pt-6 pb-8 my-12"
          // className="mx-10 md:mx-20 lg:mx-32 xl:mx-40 mt-10"
          className="generate-coupon-modal bg-white shadow-md rounded px-8 pt-6 pb-8 my-12 mx-10 md:mx-20 lg:mx-32 xl:mx-40 mt-10"
          isOpen={this.state.generateCouponIsOpen}
          onRequestClose={this.closeCouponGenerator}
          style={{ CouponStyle }}
          contentLabel="coupon generator modal"
        >
          <GenerateCouponForm />
        </Modal>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="sign in screen"
        >
          <SignInScreen
            inUnauthorized={this.props.inUnauthorized}
            isSignedIn={this.props.in}
          />
        </Modal>

        <Modal
          isOpen={this.props.togglerIsOpen}
          onRequestClose={this.props.closeDirectionsToggler}
          style={buildingSelectorStyles}
          contentLabel="select building"
        >
          <select
            style={{ fontSize: "x-large", width: "65vw" }}
            value={this.state.selectedBuilding}
            onChange={this.handleBuildingChange}
            className={
              "block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            }
          >
            {this.props.allBuildings
              ? this.props.allBuildings.map((building) => (
                <option key={building.id} value={building.id}>
                  {building.data.name}
                </option>
              ))
              : ""}
          </select>
        </Modal>
      </>
    );
  }
}

export default withRouter(Header);
