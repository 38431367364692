import React, { useState } from "react";
import { LoadScript } from "@react-google-maps/api";
import { withRouter } from "react-router-dom";

const librariesArray = ["places"];
function YakScript({ children, match }) {
  console.log(match, "props in yakscript");
  const [isGoogleMapScriptLoaded] = useState(
    window.google && window.google.maps && match.path !== "/warehouse/:id" && match.url !== "/directions"
  );
  if (isGoogleMapScriptLoaded) {
    console.log("do not load google script");
    return children;
  }
  console.log("load google script");
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_NOT_SECRET_CODE}
      libraries={librariesArray}
    >
      {children}
    </LoadScript>
  );
}
export default withRouter(YakScript);

// export default class YakMap extends LoadScript {
//   componentDidMount() {
//     const cleaningUp = true
//     const isBrowser = typeof document !== "undefined" // require('@react-google-maps/api/src/utils/isbrowser')
//     const isAlreadyLoaded = window.google && window.google.maps && document.querySelector('body.first-hit-completed') // AJAX page loading system is adding this class the first time the app is loaded
//     if (!isAlreadyLoaded && isBrowser) {
//       // @ts-ignore
//       if (window.google && !cleaningUp) {
//         console.error("google api is already presented")
//         return
//       }

//       this.isCleaningUp().then(this.injectScript)
//     }

//     if (isAlreadyLoaded) {
//       this.setState({ loaded: true })
//     }
//   }

// }
