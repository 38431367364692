import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { MdPhone } from "react-icons/md";
import { getDateString, get30DaysFromNow } from "../utils";
import { toast } from "react-toastify";

const days = get30DaysFromNow();
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "75%",
    },
  },
}));

export default function FreeForm(props) {
  console.log(props, "= props");
  const classes = useStyles();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dialog, setDialog] = useState(false);
  const [payment, setPayment] = useState(null);

  // const {allBuildings, selectedBuildingIndex}= props
  // let chosenBuildingLat;
  // let chosenBuildingLong;
  // let chosenBuildingLatLongObject = {};
  // chosenBuildingLatLongObject.lat=allBuildings[selectedBuildingIndex].data.location._lat
  // chosenBuildingLatLongObject.long=allBuildings[selectedBuildingIndex].data.location._long
  // console.log(chosenBuildingLatLongObject, "= choselBuildingLatLongObj")

  const sendEmail = async (clientObject) => {
    try {
      const res = await fetch(
        "https://us-central1-firemap-28d9b.cloudfunctions.net/sendMail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(clientObject),
        }
      );
      const message = await res.text();
      console.log(message); // print data missing
      if (
        message ===
        "email sent succesfully after sendMail was executed in index.js"
      ) {
        // window.scroll({
        //   top: 0,
        //   behavior: 'smooth'
        // });
        setDialog(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onCloseDialog = () => {
    setDialog(false);
    console.log("success dialog closed");
    props.history.push("/");
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    // console.log('he treid to submit the freeform')
    //make the client object
    toast.info('We are processing your request... please be patient.. dont go anywhere just yet ;)')
    // console.log('fname = ', fname)
    // console.log('lname = ', lname)
    // console.log('email = ', email)
    // console.log('phone = ', phone)

    // console.log('freeform submitted the props of freeform = ', props)

    console.log("the buildingAddress", props.destinationBuilding);
    console.log("the selected warehouse/office unit:", props.selectedUnit);
    console.log("the fileUrl prop", props.planUrl);

    let origin;
    let destination;

    props.directions
      ? (origin = [
        props.directions.request.origin.location.lat().toString(),
        props.directions.request.origin.location.lng().toString(),
      ])
      : (origin = [props.userLatLong.lat, props.userLatLong.lng]);

    props.directions
      ? (destination = [
        props.directions.request.destination.location.lat().toString(),
        props.directions.request.destination.location.lng().toString(),
      ])
      : (destination = [
        props.buildings[props.selectedBuildingIndex].data.location._lat,
        props.buildings[props.selectedBuildingIndex].data.location._long,
      ]);
    console.log("the origin :", origin);
    console.log("the destination :", destination);

    let timeArray = [
      getDateString(days[props.selectedDay]),
      props.selectedTime,
    ];
    let clientObject = {};
    clientObject.meetingDate = timeArray;
    clientObject.given_name = fname;
    clientObject.surname = lname;
    clientObject.email_address = email;
    clientObject.payer_id = "coupon_" + Date.now();
    clientObject.phone = phone;
    clientObject.phone_type = "MOBILE";
    clientObject.origin = origin;
    clientObject.destination = destination;
    clientObject.buildingAddress = props.destinationBuilding;
    clientObject.planUrl = props.planUrl;
    clientObject.selectedUnit = props.selectedUnit;
    clientObject.notificationImage =
      props.buildings[props.selectedBuildingIndex].data.fileUrl;

    //add stuff to client object

    function convert2epoch(meetingDate) {
      const apt = new Date(meetingDate[0]);
      apt.setHours(apt.getHours() + Number(meetingDate[1]) + 8);
      return apt.toLocaleString().replace(/(.*)\D\d+/, "$1");
    }
    let meetingDateString = convert2epoch(timeArray);
    clientObject.meetingDateString = meetingDateString;

    console.log("the chosen time as string is :", meetingDateString);

    console.log("clientObject = ", clientObject);
    sendEmail(clientObject);
    console.log("called sendmail from within FreeFrom component");
    setPayment(clientObject);
  };

  const testlength = (val) => {
    const length = val.length;
    return length > 22 && true;
  };

  return (
    <>
      <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <TextField
            required
            value={fname}
            onChange={(e) => setFname(e.target.value)}
            error={testlength(fname) ? true : false}
            label={testlength(fname) ? "first name problem" : "first name"}
            helperText={testlength(fname) ? "too long" : ""}
            variant="outlined"
            type=""
          />
        </div>
        <div>
          <TextField
            required
            value={lname}
            onChange={(e) => setLname(e.target.value)}
            error={testlength(lname) ? true : false}
            label={testlength(lname) ? "first name problem" : "first name"}
            helperText={testlength(lname) ? "too long" : ""}
            variant="outlined"
            type=""
          />
        </div>
        <div>
          <TextField
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={testlength(email) ? true : false}
            label={testlength(email) ? "email problem" : "email"}
            helperText={testlength(email) ? "too long" : ""}
            variant="outlined"
            type=""
          />
        </div>
        <div>
          <TextField
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value.replace(/\D/, ""))}
            error={testlength(phone) ? true : false}
            label={testlength(phone) ? "phone problem" : "phone"}
            helperText={testlength(phone) ? "too long" : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdPhone style={{ color: "silver" }} />
                  +1
                </InputAdornment>
              ),
            }}
            variant="outlined"
            type=""
          />
        </div>
        <Button
          type="submit"
          value="Submit"
          color="primary"
          style={{ marginLeft: "8px" }}
        >
          Free Appointment
        </Button>
      </form>

      <Dialog open={dialog} onClose={onCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>{"Booking confirmed"}</DialogTitle>
        <DialogContent>
          {payment ? (
            <div style={{ marginBottom: 20 }}>
              <div
                style={{
                  marginBottom: 10,
                  color: "var(--colorMain)",
                  fontSize: 22,
                  fontWeight: 600,
                }}
              >
                Price : ${props.price}
              </div>
              <div>
                Created at:{" "}
                {/* {new Date(payment.create_time).toDateString()} */}
                {new Date().toLocaleString()}
              </div>
              <div>Name: {`${fname} ${lname}`}</div>
              <div>Email: {email}</div>
              <div>
                Info: We've sent an mail with directions to your selected
                building as well as the spaceplan of your selected unit, check
                your spam folder if you've not recieved it.
              </div>
            </div>
          ) : (
              <div />
            )}
          <Button onClick={onCloseDialog} color="primary">
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
