import React, { PureComponent } from "react";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const mapTour = [
  {
    stepInteraction: false,
    position: "bottom",
    selector: '[data-tut="reactour__iso"]',
    content: `Ok, let's start with the name of the Tour that is about to begin.`,
  },
  {
    selector: '[data-tut="reactour__streetview"]',
    content: `This is the streetview...`,
  },
  {
    selector: '[data-tut="reactour__next"]',
    content: `This is the the button to cycle...`,
  },
  {
    selector: '[data-tut="reactour__center"]',
    content: `This is the the center button...`,
  },
  {
    selector: '[data-tut="reactour__contact"]',
    content: `contact this guy...`,
  },
];

const unAuthrizedTour = [
  // {
  //   stepInteraction: false,
  //   position: "bottom",
  //   selector: '[data-tut="reactour__buildingPicker"]',
  //   content: `This is the building selector, much like the play button on the map page you can use it to view other buildings`,
  // },
  {
    stepInteraction: false,
    selector: '[data-tut="reactour__unrestrictedForm"]',
    content: `See any Listings you like here?`,
  },
  {
    stepInteraction: false,
    selector: '[data-tut="reactour__unrestrictedPlans"]',
    content: `Check out the Space Plans in this column`,
  },
  // {
  //   selector: '[data-tut="reactour__contact"]',
  //   content: `and contact the manager.`,
  // },
];

const adminTour = [
  {
    stepInteraction: false,
    position: "bottom",
    selector: '[data-tut="reactour__adminFormUpload"]',
    content: `This is the admin form Uplad to upload units and photos`,
  },
  {
    stepInteraction: false,
    position: "bottom",
    selector: '[data-tut="reactour__adminTables"]',
    content: `This is the admin tables to update and delete your units`,
  },
];

class TourComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      steps: [],
    };
  }

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  static getDerivedStateFromProps(props) {
    const { location } = props;
    //console.log("getDervived state from propslocation", location);
    //console.log("he is logged in or not? ?::", props.in);
    console.log(location.pathname, 'tour location')
    
    if (location.pathname === "/"||location.pathname.split("/")[1]==='warehouse') {
      return { steps: mapTour };
    } else if (location.pathname.split("/")[1] === "buildings" && props.in) {
      return { steps: adminTour };
    } else if (location.pathname.split("/")[1] === "buildings" && !props.in) {
      return { steps: unAuthrizedTour };
    } 
  }

  render() {
    // console.log('the props of TourCompnent are:', this.props)
    // console.log('props.location.pathname', this.props.location.pathname.split('/')[1])
    const { steps } = this.state;

    return (
      <Tour
        // update={location.pathname}
        steps={steps}
        // isOpen={this.props.isTourOpen}
        onAfterOpen={this.disableBody}
        onBeforeClose={this.enableBody}
        maskClassName="mask"
        className="helper"
        rounded={5}
        onRequestClose={this.props.closeTour}
        {...this.props}
      />
    );
  }
}

export default TourComponent;
