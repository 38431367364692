import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import MyStreetView from "./StreetView";
import { CSSTransition } from "react-transition-group";
import Card from "./Card";
import loopneticon from "./loopneticon.png";
import Control from "./Control";
import { Map } from "../MapGlobal";
import { Marker, MarkerClusterer } from "@react-google-maps/api";
import { MapStyles } from "./MapStyles";
import { FaPlay } from "react-icons/fa";
import "./Mapcss.css";
import { toast } from "react-toastify";
import {
  // useLocation,
  Link
} from "react-router-dom";
import { withRouter } from "react-router-dom";
import YakScript from "../Scripts/YakScript";

const originalcenter = { lng: -87.71965, lat: 42.02793 };
const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
};

let noPoi = [
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
];

const HomeMapOptions = {
  styles: noPoi,
  disableDefaultUI: true,
};

const HomeMap = (props, match) => {
  const [map, setMap] = useState(false);
  const [zoom, setZoom] = useState(14);
  const [center, setCenter] = useState(originalcenter);
  const [selectedBuildingIndex, setBuildingIndex] = useState(
    props.selectedBuildingIndex
  );

  const CenterFocus = () => {
    console.log(zoom, "zoom");
    console.log(center.lng, "center.lng");
    console.log(originalcenter.lng, "orig.lng");
    console.log(center.lat, "center.lat");
    console.log(originalcenter.lat, "orig.lat");
    if (
      zoom !== 14 ||
      center.lng() !== originalcenter.lng ||
      center.lat() !== originalcenter.lat
    ) {
      setCenter(originalcenter);
      setZoom(14);
    } else {
      toast(
        "the map is already centered, try exploring a bit, you can also click the next button or a map icon",
        {
          position: "bottom-left",
          autoClose: 20000,

        }
      );
    }
  };
  const handleBuildingIndexChange = (selectedBuildingIndex) => {
    // console.log("handleBuilding indexChangoe was called form onsid eof map");
    props.onSelectBuildingIndex(selectedBuildingIndex);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    let buildingsArray = []
    props.buildings.forEach(x => buildingsArray.push(x.data.name))
    // console.log(buildingsArray, 'buildingsArray')
    for (let i = 0; i < buildingsArray.length; i++) {
      console.log((i) + ": " + buildingsArray[i]);
    }

    if (matchPathID) {
      console.log('there is an id of a build in the match')
      convertAndFind(matchPathID)
    } else {
      console.log('there is No warehouse')
      props.history.push(`/warehouse/${theCurrentBuildingSlug}`)
    }
  }, []);


  let length = props.buildings.length;
  let theCurrentBuilding = props.allBuildings[props.selectedBuildingIndex].data.name
  let theCurrentBuildingSlug = theCurrentBuilding.split(' ').join('_')
  let theNextBuilding = props.allBuildings[(props.selectedBuildingIndex + 1) % length].data.name
  let theNextBuildingSlug = theNextBuilding.split(' ').join('_')

  const matchPathID = props.match.params.id
  // console.log(props.match, '= the match you will decide')


  const convertAndFind = (pathId) => {
    console.log('convert and find was called')
    pathId = pathId.split('_').join(' ')
    // console.log(pathId, 'returned pathId')
    // let thisBuilding=props.allBuildings.find(x=>x.data.name===pathId)
    // console.log(thisBuilding, "=was found This Building")  
    let index = props.allBuildings.findIndex(x => x.data.name === pathId)
    if (index !== -1) {
      setBuildingIndex(index)
      handleBuildingIndexChange(index)
    } else {
      props.history.push(`/warehouse/${theCurrentBuildingSlug}`)
    }
  }


  return (
    <>
      <div
        id="mapcontainer"
        style={{
          visibility: map ? "visible" : "hidden",
          // marginBottom: "-45px",
          // height: "calc(100vh - 64px)",
          height: "100vh",
          position: "relative",
        }}
      >
        <Map
          options={HomeMapOptions}
          onLoad={() => setMap(true)}
          zoom={zoom}
          className="row"
          center={center}
          onCenterChanged={function () {
            const center = this.getCenter();
            setCenter(center);
            // let buildingCount = 0
            // console.log(this.getBounds(), "=the bounds")
            // console.log(this)
            // const bounds = this.getBounds()
            // for (let j = 0; j < this.markers.length; j++) {
            //   if (bounds.contains(this.markers[j].getPosition()) === true) {
            //     // console.log('marker'+map.markers[j].title+' in bounds')
            //     buildingCount++
            //   }
            // }
            // console.log(buildingCount)
          }
          }
          onZoomChanged={function () {
            const zoom = this.getZoom();
            setZoom(zoom);
          }}
        >
          <MarkerClusterer
            options={options}
            onClick={props.onMarkerClustererClick}
          >
            {(clusterer) =>
              props.buildings.map((marker, ind) => (
                // <Link
                //   // to={`/warehouse/${???}`}
                //   to={'/'}
                //   key={marker.id}
                // >
                <Marker

                  key={marker.id}
                  icon={{
                    url: loopneticon,
                    scaledSize:
                      ind === selectedBuildingIndex
                        ? new window.google.maps.Size(27, 45)
                        : new window.google.maps.Size(19, 29),
                  }}
                  position={{
                    lat: marker.data.location._lat,
                    lng: marker.data.location._long,
                  }}
                  onClick={(e) => {
                    setBuildingIndex(ind);
                    handleBuildingIndexChange(ind);
                    let theCurrentBuilding = props.buildings[ind].data.name
                    let theCurrentBuildingSlug = theCurrentBuilding.split(' ').join('_')
                    props.history.push(`/warehouse/${theCurrentBuildingSlug}`)
                    let newCenter = {
                      lng: Number(props.buildings[ind].data.location.longitude),
                      lat: Number(props.buildings[ind].data.location.latitude),
                    };
                    setCenter(newCenter);
                  }}
                  clusterer={clusterer}
                />
                // </Link>

              ))
            }
          </MarkerClusterer>
        </Map>

        <div
          style={{
            position: "absolute",
            height: "0px",
            top: "11px",
            left: "20px",
            right: "20px",
          }}
        >
          <div
            style={{
              height: " 00%",
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {props.buildings.length > 0 && (
              <CSSTransition
                in={true}
                appear={true}
                timeout={1000}
                classNames="fade"
              >
                <Card
                  fileUrl={props.buildings[selectedBuildingIndex].data.fileUrl}
                  yearBuilt={props.buildings[selectedBuildingIndex].data.built}
                  totalUnits={props.units}
                  cardLink={props.cardLink}
                  description={
                    props.buildings[selectedBuildingIndex].data.description
                  }
                  name={props.buildings[selectedBuildingIndex].data.name}
                  newLink={props.buildings[selectedBuildingIndex].id}
                />
              </CSSTransition>
            )}
            <div>
              <YakScript>
                <MyStreetView
                  isHidden={props.hiddenStreet}
                  name={props.buildings[selectedBuildingIndex].data.name}
                  location={{
                    lng: Number(
                      props.buildings[selectedBuildingIndex].data.location
                        .longitude
                    ),
                    lat: Number(
                      props.buildings[selectedBuildingIndex].data.location
                        .latitude
                    ),
                  }}
                />
              </YakScript>
            </div>
          </div>
        </div>
      </div>

      <div className="controlContainer"
      // style={MapStyles.controlContainer}
      >
        <Control>
          <div data-tut="reactour__center" onClick={CenterFocus}>
            <div
              title={MapStyles.centerControl.title}
              style={MapStyles.centerControl}
            >
              <span id="centerText">Center</span>
            </div>
          </div>
        </Control>
        <Control>
          <Link
            to={`/warehouse/${theNextBuildingSlug}`}
            data-tut="reactour__next"
            onClick={() => {
              let length = props.buildings.length;
              setBuildingIndex((selectedBuildingIndex + 1) % length);
              handleBuildingIndexChange((selectedBuildingIndex + 1) % length);
              if (zoom !== 17) { setZoom(17) }
              let newCenter = {
                lng: Number(
                  props.buildings[(selectedBuildingIndex + 1) % length].data
                    .location.longitude
                ),
                lat: Number(
                  props.buildings[(selectedBuildingIndex + 1) % length].data
                    .location.latitude
                ),
              };
              setCenter(newCenter);
            }}
          // dataTut="reactour__next"
          >
            <button
              aria-label="advance to next building" id="playControlHolder"
              title={MapStyles.playControl.title}
              style={MapStyles.playControl}
            >
              <span id="nextText">Next Building</span>
              <FaPlay
                style={{
                  width: "1.5em",
                  height: "1.5em",
                  color: "white",
                  fontSize: "18px",
                }}
              />
            </button>
          </Link>
        </Control>
      </div>

      <Footer
        id="homeFooter"
        style={{
          WebkitBoxShadow: "0px 0 10px rgba(0, 0, 0, 0.8)",
          MozBoxShadow: "0px 0 10px rgba(0, 0, 0, 0.8)",
          boxShadow: "0px 0 10px rgba(0, 0, 0, 0.8)",
        }}
      />
    </>
  );
};

export default withRouter(HomeMap);
