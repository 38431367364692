import React, { Component } from "react";
import { withRouter } from "react-router-dom";

//the select should have its values set by the url,
//right now if you select the building with the picker it changes
//the url but the url does not effect any change on the picker.
//the problem is what if the user comes to the page from a link
//look up managed components or managed forms , make the value of the select managed by react.

//non signed in users should not see anything about updating or deleting
//the way to implement this is that in signin.js the component has a state property islogged in set to
//false if the user is not logged in and true if he is, so we need to get that prop into App the same way we did building picker
//the old selected building in github the one that used the callback to lift up the state. do the same for the component Signinform i.e. the sate of
//signin form gets lifted up to the state of the app

//pass that state down into the table component and then use ternary operators to not show editing ui.

// we need a way for buildingPicker to tell the
// whole app that the selectedBuilding has changed

class BuildingPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // value: this.props.selectedBuilding?this.props.selectedBuilding.params.id:null,
      value: this.props.selectedBuilding.params.id,
    };

    this.onSelect = this.onSelect.bind(this);
  }
  //buildingPicker has to call ChangeSelectedBuilding when the user makes a selection of building

  componentDidMount() {
    //make some call to firebase
    //in here to get the list of buildings?
    console.clear()
    console.log(this.props.match.params.id, "=match")

    const isCurrentBuilding = (building) => building.id === this.props.match.params.id;

    console.log(this.props.allBuildings.findIndex(isCurrentBuilding), "this the current building index");
    let currentIndex = this.props.allBuildings.findIndex(isCurrentBuilding)
    this.props.onSelectBuildingIndex(currentIndex);




  }

  onSelect(e) {
    var value = e.target.value;
    // this.props.ChangeSelectedBuilding(value)
    this.setState({ value: value });
    const thisbuilding = this.props.allBuildings
      .filter((building) => building.id === value)
      .pop()
      .data.name.split(" ")
      .join("_");
    this.props.history.push(`/buildings/${value}/${thisbuilding}`);

    // console.log('this.state.value is here')
    // console.log(this.state.value)
    const currentSelectedIndex = this.props.allBuildings
      .map(function (x) {
        return x.id;
      })
      .indexOf(value);
    console.log(currentSelectedIndex, "this si the currently selected index");
    this.props.onSelectBuildingIndex(currentSelectedIndex);
  }

  render() {
    // console.log(this.props,'this.props from inside of the building picker')
    console.log(this.props.index, "now we see the current Index it shoudl be the correct of what is")
    return (
      <div
        data-tut="reactour__buildingPicker"
        style={{ zIndex: 0 }}
        className={"inline-block relative w-64 .z-0"}
      >
        <select
          // style={{zIndex:0}}
          className={
            "block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          }
          value={this.state.value}
          onChange={this.onSelect}
        >
          {/* {this.props.allBuildings&&this.props.allBuildings.map(building=><option key={building.id} value={building.id}>{building.data.name}</option>)}     */}
          {this.props.allBuildings.map((building) => (
            <option key={building.id} value={building.id}>
              {building.data.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

// Objects are not valid as a React child (found: object with keys {id, data}). If you meant to render a collection of children, use an array instead.
//     in option (at buildingPicker.js:17)

export default withRouter(BuildingPicker);
