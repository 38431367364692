import React from "react";
import { GoogleMap } from "@react-google-maps/api";
import YakScript from "../Scripts/YakScript"
const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 42.02793,
  lng: -87.71965,
};

// const libraries = ["places"];
function Map(props) {
  return (
    <YakScript
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        options={props.options}
        {...props}
      >
        <>{props.children}</>
      </GoogleMap>
    </YakScript>
  );
}

export default React.memo(Map);
