import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "./streetview.css";

import { StreetViewPanorama, GoogleMap } from "@react-google-maps/api";

import { FaMinus, FaPlus } from "react-icons/fa";

const sizeToggleContainer = {
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  top: "0px",
  zIndex: 2,
};

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};
const streetContainer = {
  background: "#fff",
  border: "3px solid white",
  borderRadius: "2px",
  display: "inline-block",
  zIndex: "1",
  cursor: "pointer",
  transitionProperty: "height, width",
  transitionDuration: ".6s, .8s",
  transitionTimingFunction: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
  transitionDelay: "0s, 1s",
  WebkitTransitionProperty: "height, width",
  MsTransitionProperty: "height, width",
  OTransitionProperty: "height, width",
  WebkitTransitionDuration: ".6s, .8s",
  MsTransitionDuration: ".6s, .8s",
  OTransitionDuration: ".6s, .8s",
  WebkitTransitionTimingFunction: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
  MsTransitionTimingFunction: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
  OTransitionTimingFunction: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
  WebkitTransitionDelay: "0s, 1s",
  MsTransitionDelay: "0s, 1s",
  OTransitionDelay: "0s, 1s",
};
const sizeToggler = {
  position: "absolute",
  right: "2px",
  top: "2px",
  fontWeight: "bold",
  borderLeft: "2px solid white",
  borderBottom: "2px solid white",
  paddingRight: "0px",
  backgroundColor: "#131212",
  borderRadius: "0px 0px 0px 2px",
};
const enlargeToggler = {
  position: "absolute",
  left: "2px",
  top: "2px",
  fontWeight: "bold",
  borderRight: "2px solid white",
  borderBottom: "2px solid white",
  paddingLeft: "0px",
  backgroundColor: "#131212",
  borderRadius: "0px 0px 2px 0px",
};
const StreetViewPanormaWithAnOverlayView = (props) => (
  <GoogleMap
    mapContainerStyle={mapContainerStyle}
    zoom={7}
    center={props.center}
  // mapTypeId='streetview'
  >
    <StreetViewPanorama
      position={props.center}
      visible={true}
      options={{
        disableDefaultUI: true,
        enableCloseButton: false,
      }}
    />
  </GoogleMap>
);

const MyStreetView = (props) => {
  const [state, setState] = useState({
    large: false,
    medium: true,
    small: false,
  });

  const handleMinimize = () => {
    setState({
      //handle minimize only if large or medium
      large: false,
      medium: state.large ? true : false,
      small: state.large ? false : true,
    });
  };

  const handleMaximize = () => {
    setState({
      //handle maximize only if small or medium
      small: false,
      medium: state.small ? true : false,
      large: state.small ? false : true,
    });
  };

  const [center, setCenter] = useState({ lng: -87.71965, lat: 42.02793 });
  //I pass dependency array of props.center it only runs when that
  //specific prop changes
  useEffect(() => {
    // return () => {
    // console.log("use effect run wet3456435645y356yretye6y3");
    // console.log(props, ": props");
    setCenter(props.location);
    // };
  }, [props]);

  useEffect(() => {
    // const arrow = document.getElementsByClassName("gm-iv-small-container");
    // console.log("arrow length: ",arrow);
    // console.log("arrow length: ",arrow.length);
  }, []);
  if (props.isHidden)
    return (
      <CSSTransition
        in={true}
        appear={true}
        timeout={3000}
        classNames="fade"
        // set key to fixed value like key=‘myKey’ and it will work.
        key={props.name}
      // key='myKey'
      >
        <div
          data-tut="reactour__streetview"
          className="street-widget-container"
        >
          <div className="size-toggles-container" style={sizeToggleContainer}>
            <div
              // className="sizeToggler"
              style={sizeToggler}
              onClick={() => {
                handleMaximize();
              }}
            >
              {state.large ? null : (
                <FaPlus
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                    color: "#2196f3",
                    margin: "1px",
                  }}
                />
              )}
            </div>

            <div
              // className="enlargeToggler"
              style={enlargeToggler}
              onClick={() => {
                handleMinimize();
              }}
            >
              {state.small ? null : (
                <FaMinus
                  style={{
                    width: "1.5em",
                    height: "1.5em",
                    color: "greenyellow",
                    margin: "1px",
                  }}
                />
              )}
            </div>
          </div>

          <div
            id={"viewCard"}
            // style={
            //   state.large
            //     ? { width: "80vw", height: "50vh" }
            //     : state.medium
            //     ? { width: "35vw", height: "30vh" }
            //     : { width: "100px", height: "100px" }

            // }
            style={streetContainer}
            className={`streetCard 
          ${state.large
                ? "largeStreet"
                : state.medium
                  ? "mediumStreet"
                  : "smallStreet"
              }`}
          >
            <StreetViewPanormaWithAnOverlayView center={center} />
          </div>
        </div>
      </CSSTransition>
    );
  return null;
};

export default MyStreetView;
