import React, { Component } from "react";
import LoadingBar from "react-top-loading-bar";

export default class ExampleWithRefs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wasCompleted: false,
      // loadingBarProgress: 0
    };
  }

  // add = value => {

  //   this.setState(prevState=>{
  //     return{
  //       loadingBarProgress: prevState.loadingBarProgress + value
  //     }
  //   });
  // };

  componentDidMount() {
    console.log("component mounted");
    // this.LoadingBar.continuousStart();
    // setTimeout(() => { this.LoadingBar.complete() }, 4000);
    // if(this.props.progress){
    //   this.LoadingBar.add(this.props.progress);
    // }
  }

  componentDidUpdate() {
    // console.log(this.props, 'this.props')
    if ("startLoad" && "isComplete" in this.props) {
      this.checkDirections(this.props);
    }
  }

  checkDirections = (props) => {
    console.log("hi from checkdirections");
    if (this.state.wasCompleted) {
      // return console.log('Loading bar detected mapDirections', props.mapDirections)
      return;
    } else if (!props.isComplete && props.startLoad) {
      this.LoadingBar.continuousStart();
    } else if (this.props.isComplete) {
      this.LoadingBar.complete();
      this.setState({ wasCompleted: true });
    }
  };

  // addBuildingProgress = (props) => {

  // }

  render() {
    // (!this.props.isComplete&&this.props.startLoad)&&this.LoadingBar.continuousStart()
    // this.props.isComplete && this.LoadingBar.complete();

    //this.checkDirections(this.props)
    return (
      <LoadingBar
        height={this.props.height}
        color={this.props.color}
        onRef={(ref) => (this.LoadingBar = ref)}
      />
    );
  }
}
