import React, { useState, useEffect } from "react";
import { Container, Grid, Button } from "@material-ui/core";
import { FiCalendar } from "react-icons/fi";
import { FaWarehouse } from "react-icons/fa";
import { getDateString, getTimeString, get30DaysFromNow } from "../utils";
import CheckoutForm from "./CheckoutForm";
import Directions from "./Directions3";
import Fab from "@material-ui/core/Fab";
import Modal from "react-modal";
import IntendedUnitSelector from "./IntendedUnitSelector";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Coupon from "../FreeForm/Coupon";
import { db } from "../../firebase";
import FreeForm from "../FreeForm/FreeFrom";
const topMarg = { marginTop: "10vh" };
const days = get30DaysFromNow();
const times = [
  {
    from: 8,
    to: 9,
  },
  {
    from: 9,
    to: 10,
  },
  {
    from: 10,
    to: 11,
  },
  {
    from: 11,
    to: 12,
  },
  {
    from: 12,
    to: 13,
  },
  {
    from: 13,
    to: 14,
  },
  {
    from: 14,
    to: 15,
  },
  {
    from: 15,
    to: 16,
  },
  {
    from: 16,
    to: 17,
  },
  {
    from: 17,
    to: 18,
  },
  {
    from: 18,
    to: 19,
  },
  {
    from: 19,
    to: 20,
  },
];

function PricePreview({ ...props }) {
  // console.log("the props of pricepreview", props);

  const closeModal = () => {
    console.log("he clicked outside now close it");
    setModalIsOpen(false);
  };

  const handleClick = () => {
    console.log("fab was clicked now open modal");
    setModalIsOpen(true);
  };

  const [modalOpenStatus, setModalIsOpen] = useState(false);
  const [SelectedUnitName, setSelectedUnitName] = useState("");
  const [userLatLong, setUserLatLong] = useState({ lat: "", lng: "" });
  const [adressString, setadress] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [AllCoupons, setAllCoupons] = useState([]);

  useEffect(() => {
    db.collection("Coupons")
      .get()
      .then((snapshot) => {
        var allCoupons = snapshot.docs.map((coupon) => ({
          id: coupon.id,
          data: coupon.data(),
        }));
        setAllCoupons(allCoupons);
        // console.log('pricepreview the state of allcoupons=', allCoupons)
      });
  }, []);

  const customStyles = {
    content: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "70%",
      height: "-moz-min-content!important",
      borderRadius: "4px",
      position: "static!important",
      marginTop: "10vh",
    },
  };

  const destinationBuilding =
    props.buildings[props.selectedBuildingIndex].data.name;

  const RunBookingValidation = (selectedUnit, selectedTimeDay) => {
    if (
      (!selectedUnit ||
        selectedUnit === "no unit" ||
        selectedUnit === "nothin") &&
      !selectedTimeDay
    ) {
      return (
        <div style={topMarg} className={"booking-validation"}>
          First, select a meeting time by clicking the button under Start Time,
          then select a building and a unit by clicking on the Fab to the right.
        </div>
      );
    } else if (
      !selectedUnit ||
      selectedUnit === "no unit" ||
      selectedUnit === "nothin"
    ) {
      return (
        <div style={topMarg} className={"booking-validation"}>
          Select a building and a unit by clicking on the Fab to the right.
        </div>
      );
    } else if (!selectedTimeDay) {
      return (
        <div style={topMarg} className={"booking-validation"}>
          Change the selected meeting time by clicking the button under Start
          Time
        </div>
      );
    } else if (
      selectedUnit &&
      selectedUnit !== "no unit" &&
      selectedUnit !== "nothin"
    ) {
      // console.log("PricePreview the selectedUnit :", selectedUnit);
      return (
        <div style={topMarg}>
          <Coupon
            setIsFree={setIsFree}
            testProp={"lorum ipsum prop"}
            AllCoupons={AllCoupons}
          />
          {/* change true back to isFree when finished building the freeform    */}
          {!isFree && (
            <CheckoutForm
              planUrl={planUrl}
              selectedUnit={selectedUnit}
              destinationBuilding={destinationBuilding}
              userLatLong={userLatLong}
              bookData={""}
              price="25"
              {...props}
            />
          )}
          {isFree && (
            <FreeForm
              planUrl={planUrl}
              selectedUnit={selectedUnit}
              destinationBuilding={destinationBuilding}
              userLatLong={userLatLong}
              {...props}
            />
          )}
        </div>
      );
    }
  };

  // let UnitObj = props.Units.find(
  //   (unit) => unit.data.name === SelectedUnitName
  // );
  // userLatLong?console.log('User Lat Long', userLatLong):console.log('no userLatLong')

  let UnitObj = props.Units.find(
    (unit) =>
      unit.data.name === SelectedUnitName &&
      unit.data.building_id === props.buildings[props.selectedBuildingIndex].id
  );

  // console.log('the unitObj', UnitObj)
  // console.log('selectedUnitName', SelectedUnitName)
  let planUrl;
  // UnitObj ? console.log("the UnitObj", UnitObj) : console.log("no unitObj");

  // SelectedUnitName&&UnitObj
  //   ?planUrl = UnitObj.data.fileUrl
  //   :console.log("PricePreview no selected Unit Status");

  SelectedUnitName && UnitObj && (planUrl = UnitObj.data.fileUrl);

  // planUrl?console.log('there is planUrl',planUrl):console.log('no Plan Url')

  return (
    <div>
      <Container
        maxWidth="lg"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        <Grid
          container
          justify="center"
          spacing={4}
          style={{ background: "var(--colorWhite)" }}
        >
          <Grid
            item
            sm={6}
            xs={12}
            style={{ boxShadow: "5px 0 5px -5px gray" }}
          >
            <Directions
              buildings={props.allBuildings}
              selectedBuildingIndex={props.selectedBuildingIndex}
              setadress={setadress}
              setUserLatLong={setUserLatLong}
              height={"300px"}
            />

            <div style={{ boxSizing: "unset" }} className="preview">
              <div className="preview-location">
                <img className="icon" src="../images/pin-up.png" alt="icon" />
                <div className="info">
                  <div className="label">Your Location (approximation):</div>
                  <div className="address">
                    {adressString ? adressString : "adress not found"}
                  </div>
                </div>
              </div>
              <div className="preview-location">
                <img className="icon" src="../images/pin-down.png" alt="icon" />
                <div className="info">
                  <div className="label">Destination:</div>
                  <div className="address">
                    {destinationBuilding
                      ? destinationBuilding
                      : "No building selected"}
                  </div>
                </div>
              </div>
              <div className="preview-location">
                <FaWarehouse
                  style={{
                    color: "#3a3973",
                    width: "27px",
                    height: "27px",
                    paddingRight: "13px",
                  }}
                  className="icon"
                />
                <div className="info">
                  <div className="label">Selected Unit:</div>
                  <div className="address">
                    {SelectedUnitName && UnitObj ? (
                      `${SelectedUnitName}, ${UnitObj.data.size} sqft, $${UnitObj.data.price} mo.`
                    ) : (
                      <span className="">No Unit selected</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="preview-time-price">
                <div className="label">
                  <FiCalendar className="icon" />
                  <div className="text">Start time:</div>
                </div>
                <div className="info">
                  {!props.selectedDay && !props.selectedTime ? (
                    <div>
                      <Link to={`/calendar`}>
                        <Button className="red-text">Select Time</Button>
                      </Link>
                    </div>
                  ) : (
                    <>
                      <div className="date">
                        {props.selectedDay
                          ? getDateString(days[props.selectedDay])
                          : "Not selected"}
                      </div>
                      <div className="time">
                        {props.selectedTime || props.selectedTime === 0
                          ? `Arrive between ${getTimeString(
                              times[props.selectedTime].from
                            )} - ${getTimeString(times[props.selectedTime].to)}`
                          : "Not selected"}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Grid>

          <Grid item sm={6} xs={12}>
            <div className="fab-container">
              <Fab
                onClick={handleClick}
                className="plus-container"
                color="primary"
                aria-label="add"
              >
                <span className="plus">+</span>
              </Fab>
            </div>

            <div style={{ boxSizing: "unset" }} className="preview">
              {RunBookingValidation(SelectedUnitName, props.selectedDay)}
            </div>
          </Grid>
        </Grid>
      </Container>
      <Modal
        isOpen={modalOpenStatus}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <IntendedUnitSelector
          closeModal={closeModal}
          setSelectedUnitName={setSelectedUnitName}
          {...props}
        />
      </Modal>
    </div>
  );
}

export default withRouter(PricePreview);
