import React, { useState, useEffect } from "react";
import {
  DirectionsService,
  DirectionsRenderer,
  Polyline,
} from "@react-google-maps/api";
import LoadingBar from "../LoadingBar";
import { toast } from "react-toastify";
// import Modal from "react-modal";

const pathOptions = {
  strokeColor: "#FF0000",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#FF0000",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1,
};

const DirectionsRendererComponent = (props) => {
  // console.log('the props directoiuj rederer comp', props)
  const [destination, setDestination] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [response, setResponse] = useState(null);
  const [path, setPath] = useState(null);
  const [doFetchDirection, setDoFetchDirection] = useState(true);
  const [startLoad, setStartLoad] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  // const [modalOpenStatus, setModalIsOpen] = useState(false);

  const notify = () =>
    toast("Too far from chicago? Perhaps take a flight and come visit us? ✈️ ");

  const {
    buildings,
    setDirections,
    setCitizenshipStatus,
    selectedBuildingIndex,
    setZoom,
  } = props;
  useEffect(() => {
    // function isFacebookApp() {
    //   var ua = navigator.userAgent || navigator.vendor || window.opera;
    //   return (
    //     ua.indexOf("FBAN") > -1 ||
    //     ua.indexOf("FBAV") > -1 ||
    //     ua.indexOf("Instagram") > -1
    //   );
    // }
    // if (isFacebookApp()) {
    //   // your action here if user using Facebook in-app browser
    //   alert(
    //     "Are you using Facebook or instagram browser? You wont be able to get directions from your location, view spaceplans, or book an appointment to see spaces, for a better experience, try pressing [...] in the upper right and Open the website in Chrome or Safari. Thank you!"
    //   );
    // }

    if (!navigator.geolocation) {
      window.alert("geolocation not allowed");
    } else {
      setStartLoad(true);

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setOrigin({
            lat,
            lng,
          });
        },
        (err) => {
          console.error(err, "err in first useEffect");
          setIsComplete(true);
          setZoom(9);
          toast(err.message, { autoClose: 5000, });
          function isFacebookApp() {
            var ua = navigator.userAgent || navigator.vendor || window.opera;
            return (
              ua.indexOf("FBAN") > -1 ||
              ua.indexOf("FBAV") > -1 ||
              ua.indexOf("Instagram") > -1
            );
          }
          if (isFacebookApp()) {
            // your action here if user using Facebook in-app browser
            toast("Using facebook or instagram? it does not support Geolocation , click the three dots (...) in the upper right or lower left to open in your devices normal web browser to use this feature", { autoClose: 13000, });
          }

        }
      );
    }
  }, []);

  const Buildinglat = Number(
    buildings[selectedBuildingIndex].data.location.latitude
  );
  const Buildinglng = Number(
    buildings[selectedBuildingIndex].data.location.longitude
  );
  useEffect(() => {
    try {
      setDestination({
        lat: Buildinglat,
        lng: Buildinglng,
      });
      setDoFetchDirection(true);
    } catch (error) {
      console.error("error second useEffect", error);
    }
  }, [Buildinglat, Buildinglng]);
  // console.clear()
  // console.log("origin RenderDirectionsComponent =", origin);
  // const closeModal = () => {
  //   console.log("he clicked outside now close it");
  //   setModalIsOpen(false);
  // };

  return (
    <>
      <LoadingBar
        color={"#f11946"}
        height={4}
        startLoad={startLoad}
        isComplete={isComplete}
      />
      {/* <Modal
        isOpen={modalOpenStatus}
        onRequestClose={closeModal}
        // style={customStyles}
      >The Browser your using does not allow geolocation, please click on the link to open <a href="https://leasewarehouses.net/directions">Directions to warehouses</a> on your devices web browser</Modal> */}
      {doFetchDirection && origin && (
        <DirectionsService
          // required
          options={{
            destination: destination,
            origin: origin,
            travelMode: "DRIVING",
          }}
          // required
          callback={(response) => {
            console.log(response, "response in RenderDirectionsComp");
            if (response !== null) {
              if (response.status === "OK") {
                console.log("response status OK=", response);
                setResponse(response);
                setDirections(response);
                setIsComplete(true);
              } else {
                console.log(
                  "response is not null but status was NOT OK: ",
                  response
                );
                setPath([origin, destination]);
                setZoom(3);
                setCitizenshipStatus(false);
                setIsComplete(true);
              }
            }
            setDoFetchDirection(false);
            response === null &&
              window.alert(
                "something went wrong check your internet connection and try again"
              );
          }}
        />
      )}

      {response !== null && (
        <DirectionsRenderer
          // required

          options={{
            directions: response,
          }}
          panel={document.getElementById("panel")}
          directions={response}
        />
      )}

      {path && (
        <Polyline onLoad={() => notify()} path={path} options={pathOptions} />
      )}
    </>
  );
};

export default DirectionsRendererComponent;
