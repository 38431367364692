
import { Helmet } from "react-helmet";
import React from "react";
import { withRouter } from "react-router-dom";

const HelmetSeo = (props) => {
    let webAdress = 'https://leasewarehouses.net'
    let imageAdress = webAdress + '/images'
    console.log(props)
    let location = props.location.pathname
    console.log(location, "loc")
    return (
        <Helmet>
            <meta
                name="twitter:card"
                content="summary_large_image"
            />
            <meta name="twitter:site" content="@leasewarehouses" />
            <meta name="twitter:creator" content="@leasewarehouses" />
            <meta property="fb:app_id" content="2726920350879631" />
            <meta name="keywords" content="North Shore Chicago Commercial Real Estate for Lease, Commercial Real Estate Nearby, Skokie Commercial Properties for Lease, Flex Space for Lease, Warehouses for Lease"></meta>
            <meta
                property="og:description"
                content={props.description}
            />
            <meta
                name="description"
                content={props.Description ? props.Description : "Available Warehouses for lease on leasewarehouses.net  Find Warehouse listings, Industrial and commercial listings, or find a broker specializing in Warehouses leases."}
            />
            <meta
                property="og:url"
                content={props.url ? props.url : webAdress + location}
            />
            <meta
                property="og:image"
                content={props.image ? props.image : `${imageAdress}${location}.jpg`}
            />
            {props.type && <meta property="og:image:type" content={props.type} />}
            <meta property="og:title" content={props.longTitle} />
            <title>{props.shortTitle}</title>
        </Helmet>
    );
}

export default withRouter(HelmetSeo);