import React from "react";
// import React, { PureComponent } from "react";
import "./logostyles.css";

const Logo = (props) => {
  return (
    // <Link to={()=>this.props.setCard()} >
    <div
      onClick={props.onClick}
      id="Logo_container"
      className="Logo_container left"
    >
      <svg
        id="Logos"
        className={"centered myshadow"}
        height={props.height}
        width={props.width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 165.69 165.4"
      >
        <g id="Logo">
          <path
            d="M134.53,137.54c-1.07-1.72-2.06-3.24-3-4.81-1.59-2.74-3.2-5.47-4.68-8.27-3.46-6.63-6.83-13.3-10.27-19.94q-7-13.47-14-27a3.8,3.8,0,0,1,2.62-5.78c2-.48,4.07-.75,6.09-1.12a2.76,2.76,0,0,0,.89-.59,3.67,3.67,0,0,0-.94-.72,3.71,3.71,0,0,0-1.2,0H76.76a3,3,0,0,0-1,0,6.21,6.21,0,0,0-1.09.75,3.81,3.81,0,0,0,1.09.58c2.26.37,4.57.57,6.8,1.09,4.79,1.13,6.21,5.49,5.37,9.63-1.38,6.78-6.33,9.77-12.21,11.39a24.28,24.28,0,0,1-16.56-1.36,27.74,27.74,0,0,1-12.86-11.7,32.56,32.56,0,0,1-4.37-19.07,36.5,36.5,0,0,1,7.9-20.44c3.87-5,8.59-8.58,14.7-10C72.43,28.45,80.06,29,87,33.94c3.79,2.71,6.38,6.42,8.61,10.48.79,1.44,1.38,3,2.18,4.44a6.86,6.86,0,0,0,1.46,1.38l.63-.37c-.25-6.83-.51-13.66-.78-21.1-.9.53-1.72.72-2,1.23-1.27,2.15-3.07,2.39-5.38,1.69-4.19-1.26-8.39-2.46-12.67-3.37a48.55,48.55,0,0,0-13.94-.42,52.06,52.06,0,0,0-14.95,3.37c-11.11,4.37-19,12-21.91,24.14-2.19,9.29-.92,18.14,4.68,26A29.71,29.71,0,0,0,47.17,91.8,56.15,56.15,0,0,0,61.8,95,65.63,65.63,0,0,0,90,91.63c.08,0,.2,0,.47.09-.48,1-.93,1.91-1.39,2.84C84,105,79,115.46,73.85,125.88c-2.14,4.36-4.57,8.61-8.69,11.28-1.79,1.17-4,1.57-6.06,2.36a7.11,7.11,0,0,0-1.15.71c.39.23.78.65,1.18.65q12.24.06,24.47,0c.41,0,.81-.41,1.22-.62a5,5,0,0,0-1.15-.73c-1.15-.38-2.35-.6-3.49-1-3.94-1.34-5.91-4.63-4.43-8.54,1.86-4.9,4.28-9.56,6.57-14.28a2.27,2.27,0,0,1,1.75-.8c7.58,0,15.16,0,22.74,0,1.46,0,2,1,2.55,2,1.63,3.27,3.24,6.55,4.8,9.86a40.86,40.86,0,0,1,2.13,5.18,4.38,4.38,0,0,1-2.38,5.48,72.92,72.92,0,0,1-7.07,2.11,6.84,6.84,0,0,0-1.33.72,4.61,4.61,0,0,0,1.55.63c6.65,0,13.31,0,20,0h1.71l.19.41c-1.85,1.3-3.67,2.67-5.58,3.88a68.35,68.35,0,0,1-29.56,10.31c-19,2-36.45-2.65-51.79-14.56-14.44-11.21-23.53-26-26.41-44.37-3.11-19.86,1.52-38,13.76-53.73,11.54-14.8,26.65-23.79,45-26.67a67.8,67.8,0,0,1,13.77-1c15.18.72,29.08,5.43,41.26,15,13.5,10.59,22.47,24.3,25.88,41.42,4,19.89-.1,38.21-11.71,54.66-2.09,3-7.91,10-8.41,10.76A2.25,2.25,0,0,1,134.53,137.54Zm-27.4-24.87H83.78c3.75-7.89,7.41-15.57,11.07-23.25l.58,0Z"
            transform="translate(-2.83 -2.83)"
            fill="white"
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </g>
        <ellipse
          cx="82.84"
          cy="82.7"
          rx="81.84"
          ry="81.7"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </svg>
    </div>
    // </Link>
  );
};

export default Logo;
