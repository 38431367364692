import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const Content = ({ history, hideStreet }) => {
  // console.log(props.history.location.pathname, 'the props in content')
  let loc = history.location.pathname;
  let pathArray = loc.split("/");
  console.log(pathArray[1], "pathArray");
  useEffect(() => {
    // Run! Like go get some data from an API.
    // (loc!=="/"||loc=="/")&&props.hideStreet()
    //is the same as
    console.clear()
    console.log('the loc', loc)
    pathArray[1] !== 'warehouse' && hideStreet();
  }, [loc]);
  return <div></div>;
};

export default withRouter(Content);
