export const MapStyles = {
  controlContainer: {
    position: "relative",
    bottom: "30px",
    display: "flex",
    justifyContent: "space-between",
    margin: "auto",
    width: "100%",
    padding: "3px",
  },
  centerControl: {
    backgroundColor: "rgb(45 85 117)",
    border: "2px solid #fff",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0,0,0,.6)",
    cursor: "pointer",
    textAlign: "center",
    marginLeft: "15px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "5px",
    paddingBottom: "4px",
    // fontSize: "19px",
    fontWeight: "500",
    lineHeight: "1.4",
    color: "white",
    // title: "Click to recenter the map",
  },
  playControl: {
    backgroundColor: "rgb(45 85 117)",
    border: "2px solid #fff",
    borderRadius: "3px",
    boxShadow: "0 2px 6px rgba(0,0,0,.6)",
    cursor: "pointer",
    textAlign: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
    paddingTop: "5px",
    paddingBottom: "4px",
    // marginRight: "5px",
    // title: "Click to view the next building",
  },
  footerMargin: {
    marginTop: "-47px",
    boxShadow: "0px 0px 15px grey",
  },
  noPoi: {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
};
