import React, {
  useState,
  // useEffect
} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

export default function Coupon(props) {
  // console.log('props of COupon', props)
  const { setIsFree, AllCoupons } = props;
  const classes = useStyles();
  const [coupon, setCoupon] = useState("");
  const [disabled, setDisabled] = useState(false);
  // const [freeForm, setFreeForm]= useState(false)

  const handleSetCoupon = (e) => {
    setCoupon(e.target.value);
    //if the val in allcoupons obj is equal to e.targ.val but its coupon bc of setcoupon
    //then hide the paypalsetfree
    let exists = AllCoupons.find(
      (x) => x.data.generatedCouponCode === e.target.value
    );
    if (exists) {
      // window.alert('that is a correct code')
      setIsFree(true);
      setDisabled(true);

      //disable the coupon form now it has completed its purpose
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    alert(`Submitting Name ${coupon}`);
    //check database if there is a record that match the coupon make sure it is not used.. when you find it set isUsed.
    //set the state to freeform
    //when the state is freeform dont render the paypal but render the freeform
    // setIsFree(true)
  };

  // useEffect(() => {
  //   db.collection('Coupons').get().then((snapshot) => {
  //     var allCoupons = snapshot.docs.map(coupon => ({
  //         id: coupon.id,
  //         data: coupon.data()
  //     }));
  //     setAllCoupons(allCoupons)
  // });
  // }, []);

  let length = coupon.length;
  const testlength = (length) => {
    return length > 6 && true;
  };

  // console.log(AllCoupons, 'in the coupon component Allcoupons')
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div>
        <TextField
          value={coupon}
          // onChange={e => setCoupon(e.target.value)}
          disabled={disabled}
          onChange={(e) => handleSetCoupon(e)}
          error={testlength(length) ? true : false}
          id="outlined-error-helper-text"
          // label="Error"
          label={testlength(length) ? "coupon problem" : "coupon"}
          helperText={testlength(length) ? "too long" : ""}
          variant="outlined"
          type=""
        />
      </div>
      {/* <button type="submit" value="Submit" >submit</button> */}
    </form>
  );
}
