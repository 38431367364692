// import React, { useEffect } from "react";
import React from "react";
import { Link } from "react-router-dom";
import Icon from "./Homelogo";
// import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./WarehouseIcon.css";

function HomePage(props) {
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     console.log("He did not click yet");
  //     props.enter();
  //   }, 9000);
  //   return () => clearTimeout(timer);
  // }, []);
  return (
    // <TransitionGroup>
    //   <CSSTransition
    //     exit={true}
    //     in={true}
    //     appear={true}
    //     //set timeout being ignored
    //     timeout={500}
    //     classNames="fade"
    //     unmountOnExit
    //   >
    <main
      className="wrapper pointer"
    // onClick={() => {
    //   props.enter();
    // }}
    >
      <Link aria-label="go to the first warehouse for lease" to={`/warehouse/8111_St_Louis`}>
        <Icon />
      </Link>
      {/* <h1 class="content">Lease Warehouses, Offices, Flex & Commerical Spaces</h1> */}
    </main>

    /* </CSSTransition>
  </TransitionGroup> */

  );
}

export default HomePage;
