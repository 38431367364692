import React from "react";
import { db } from "../../firebase";
import ImageUpload from "../ImageUpload/ImageUpload";
// import { storage } from "../../firebase";
import { GrDocumentPdf, GrClose, GrCheckmark } from "react-icons/gr";

class AdminForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: false,
      nameInput: "",
      sizeInput: "",
      priceInput: "",
      submit: "",
      units: [],
      isChecked: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.ImageUploadComponent = React.createRef();
  }

  componentDidMount() {
    db.collection("Units")
      .get()
      .then((snapshot) => {
        var newUnits = snapshot.docs.map((item) => ({
          isUpdated: false,
          id: item.id,
          data: item.data(),
        }));
        this.setState({ units: newUnits });
      });
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  handleChange(event) {
    this.setState({
      input: event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.fileUrl && !this.state.isChecked) {
      let data = {
        fileUrl: this.state.fileUrl,
        name: this.state.nameInput,
        size: this.state.sizeInput,
        price: this.state.priceInput,
        isWholeBuilding: this.state.isChecked,
        belongsTo: db
          .collection("buildings")
          .doc(this.props.selectedBuilding.params.id),
        building_id: this.props.selectedBuilding.params.id,
      };
      if (!data.name.trim() || !data.size.trim() || !data.price.trim()) {
        window.alert("one or more empty fields, fields cannot be blank");
      } else {
        db.collection("Units")
          .add(data)
          .then((snapshot) => {
            let id = snapshot.id;
            this.setState((prevState) => ({
              units: prevState.units.concat({
                data: data,
                id: id,
                isUpdated: false,
              }),
            }));
            console.log("resetting the inputs");
            this.setState({
              nameInput: "",
              sizeInput: "",
              priceInput: "",
              fileUrl: false,
              isChecked: false,
            });
            this.ImageUploadComponent.current.clearLink();
            document.getElementById("fileUpload").value = "";
          });
      }
    } else if (this.state.fileUrl && this.state.isChecked) {
      let data = {
        fileUrl: this.state.fileUrl,
        name: this.state.nameInput,
        size: this.state.sizeInput,
        isWholeBuilding: this.state.isChecked,
        belongsTo: db
          .collection("buildings")
          .doc(this.props.selectedBuilding.params.id),
        building_id: this.props.selectedBuilding.params.id,
      };
      if (!data.name.trim() || !data.size.trim()) {
        window.alert("one or more empty fields, fields cannot be blank");
      } else {
        db.collection("Units")
          .add(data)
          .then((snapshot) => {
            let id = snapshot.id;
            this.setState((prevState) => ({
              units: prevState.units.concat({
                data: data,
                id: id,
                isUpdated: false,
              }),
            }));
            console.log("resetting the inputs");
            this.setState({
              nameInput: "",
              sizeInput: "",
              priceInput: "",
              fileUrl: false,
              isChecked: false,
            });
            this.ImageUploadComponent.current.clearLink();
            document.getElementById("fileUpload").value = "";
          });
      }
    } else {
      return window.alert(
        "He said there must be an image in order to add a unit, please upload an image first"
      );
    }
  }

  onDelete = (id) => {
    db.collection("Units")
      .doc(id)
      .delete()
      .then(() => {
        this.setState({
          units: this.state.units.filter((unit) => unit.id !== id),
        });
        console.log("he deleted all text info in the row");
      });
    // .then(() => {
    //   var managerImage = storage
    //     .ref("images")
    //     .child("commercial_real_estate_broker-2.jpg");
    //   managerImage
    //     .delete()
    //     .then(function () {
    //       console.log("the maganger image was deleted");
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // });
  };

  onUpdate = (unit) => {
    db.collection("Units")
      .doc(unit.id)
      .set(unit.data)
      .then(() => {
        // unit.isUpdated=false;
        //we want the update to disappear after a successful update, the way to do it is to set
        //the units equal to the previous units, except the unit that is successfully changed should have its
        //isupdated property set to false
        //its very similar to the code in the onchage.
        // this.setState(prevState=>({})
        this.setState((prevState) => ({
          units: prevState.units.map((x) => {
            if (x.id === unit.id) {
              return { id: x.id, data: { ...x.data }, isUpdated: false };
            } else {
              return { ...x };
            }
          }),
        }));
      });
  };

  onUpload = (url) => {
    this.setState({ fileUrl: url });
    console.log("the url inside onUpload function:", url);
  };

  openImage = (imageName) => {
    console.log("imageName", imageName);
    window.open(imageName);
  };

  render() {
    let txt;
    if (this.state.isChecked) {
      txt = "entire building or floor";
    } else {
      txt = "single unit";
    }
    let selectedBuildingTitle = this.props.selectedBuilding.params.slug
      .split("_")
      .join(" ");
    // let unitsArr = this.state.units.filter(unit => unit.data.belongsTo.id === this.props.selectedBuilding.params.id)
    return (
      <div>
        <form
          data-tut="reactour__adminFormUpload"
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 my-10"
          onSubmit={this.handleSubmit}
        >
          <div className="flex my-6">
            <label className="flex items-center">
              <input
                name="isWholeBuilding"
                type="checkbox"
                onChange={this.handleChecked}
                className="form-checkbox"
              ></input>
              <span className="ml-2">
                unit is an <span className="underline">{txt}</span>
              </span>
            </label>
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name:
            </label>
            <input
              className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={this.state.nameInput}
              onChange={(e) => {
                this.setState({ nameInput: e.target.value });
              }}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="size"
            >
              Size:
            </label>
            <input
              className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={this.state.sizeInput}
              onChange={(e) => {
                this.setState({ sizeInput: e.target.value.replace(/\D/, "") });
              }}
            />
          </div>
          {!this.state.isChecked && (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="price"
              >
                Price:
              </label>
              <input
                className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={this.state.priceInput}
                onChange={(e) => {
                  this.setState({
                    priceInput: e.target.value.replace(/\D/, ""),
                  });
                }}
              />
            </div>
          )}

          <ImageUpload
            ref={this.ImageUploadComponent}
            onUpload={this.onUpload}
          />

          <div className="my-5">
            <button
              // className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              className="btn"
              type="submit"
              style={{ backgroundColor: "turquoise" }}
            >
              Submit!
            </button>
          </div>
        </form>

        <table
          data-tut="reactour__adminTables"
          className="rounded shadow-lg bg-white p5 sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-10"
        >
          <thead className=" text-white">
            <tr>
              <td className="bg-gray-300" colSpan="12">
                <span style={{ fontStyle: "italic", color: "silver" }}>
                  {selectedBuildingTitle}
                </span>{" "}
                has the following units currently available for lease
              </td>
            </tr>
            <tr className="bg-teal-400  wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
              <td className="p-3 text-left">Name</td>
              <td className="p-3 text-left">Size Sqft</td>
              <td className="p-3 text-left">Price</td>
              <td className=""></td>
              <td className=""></td>
              <td className="p-3 text-left">Plan</td>
            </tr>
          </thead>
          <tbody className="">
            {/* {unitsArr.length === 0 && <tr className="empty-unit-row"><td colSpan="12">This building currently does not have any commercial units for lease. Add some units using the form above.</td></tr>} */}
            {this.state.units.filter(
              (unit) =>
                unit.data.belongsTo.id ===
                this.props.selectedBuilding.params.id &&
                !unit.data.isWholeBuilding
            ).length === 0 && (
                <tr className="empty-unit-row">
                  <td colSpan="12">
                    This building currently does not have any commercial units for
                    lease. Add some units using the form above.
                </td>
                </tr>
              )}

            {this.state.units
              .filter(
                (unit) =>
                  unit.data.belongsTo.id ===
                  this.props.selectedBuilding.params.id &&
                  !unit.data.isWholeBuilding
              )
              .map((unit) => {
                return (
                  <tr className=" wrap sm:table-row mb-2 sm:mb-0" key={unit.id}>
                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                      <input
                        value={unit.data.name}
                        onChange={(e) => {
                          var value = e.target.value;
                          this.setState((prevState) => ({
                            units: prevState.units.map((x) => {
                              if (x.id === unit.id) {
                                return {
                                  id: x.id,
                                  data: { ...x.data, name: value },
                                  isUpdated: true,
                                };
                              } else {
                                return { ...x };
                              }
                            }),
                          }));
                        }}
                      />
                    </td>
                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                      <input
                        value={unit.data.size}
                        onChange={(e) => {
                          var value = e.target.value.replace(/\D/, "");
                          this.setState((prevState) => ({
                            units: prevState.units.map((x) => {
                              if (x.id === unit.id) {
                                return {
                                  id: x.id,
                                  data: { ...x.data, size: value },
                                  isUpdated: true,
                                };
                              } else {
                                return { ...x };
                              }
                            }),
                          }));
                        }}
                      />
                    </td>
                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                      <input
                        value={unit.data.price}
                        onChange={(e) => {
                          var value = e.target.value.replace(/\D/, "");
                          this.setState((prevState) => ({
                            units: prevState.units.map((x) => {
                              if (x.id === unit.id) {
                                return {
                                  id: x.id,
                                  data: { ...x.data, price: value },
                                  isUpdated: true,
                                };
                              } else {
                                return { ...x };
                              }
                            }),
                          }));
                        }}
                      />
                    </td>
                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                      <button onClick={() => this.onDelete(unit.id)}>
                        {/* <i className="material-icons deletered">close</i> */}
                        <GrClose />
                      </button>
                    </td>
                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                      {unit.isUpdated && (
                        <button onClick={() => this.onUpdate(unit)}>
                          {/* <i className="material-icons checkgreen">check</i> */}
                          <GrCheckmark />
                        </button>
                      )}
                    </td>

                    <td className="border-grey-light border hover:bg-gray-100 p-3 center">
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={() => this.openImage(unit.data.fileUrl)}
                      >
                        {/* <i className="material-icons">open_in_new</i> */}
                        <GrDocumentPdf className="material-icons" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <table
          data-tut="reactour__adminTables"
          className="rounded shadow-lg bg-white p5 sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-10"
        >
          <thead className=" text-white">
            <tr>
              <td colSpan="5" className="bg-gray-300">
                <span style={{ fontStyle: "italic", color: "silver" }}>
                  {selectedBuildingTitle}
                </span>{" "}
                these plans represent the entire building layout
              </td>
            </tr>
            <tr className="bg-teal-400  wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
              <td className="p-3 text-left">Name</td>
              <td className="p-3 text-left">Size Sqft</td>
              <td className=""></td>
              <td className=""></td>
              <td className="p-3 text-left">Plan</td>
            </tr>
          </thead>
          <tbody className="">
            {this.state.units.filter(
              (unit) =>
                unit.data.belongsTo.id ===
                this.props.selectedBuilding.params.id &&
                unit.data.isWholeBuilding
            ).length === 0 && (
                <tr className="empty-unit-row">
                  <td colSpan="12">
                    This building currently does not have any full building plans.
                    Add some units using the form above. Remember to check the
                    check box for a whole building or complete floor spaceplan,
                    and leave it unchecked for a rentable individual unit.
                </td>
                </tr>
              )}

            {this.state.units
              .filter(
                (unit) =>
                  unit.data.belongsTo.id ===
                  this.props.selectedBuilding.params.id &&
                  unit.data.isWholeBuilding
              )
              .map((unit) => {
                return (
                  <tr className=" wrap sm:table-row mb-2 sm:mb-0" key={unit.id}>
                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                      <input
                        value={unit.data.name}
                        onChange={(e) => {
                          var value = e.target.value;
                          this.setState((prevState) => ({
                            units: prevState.units.map((x) => {
                              if (x.id === unit.id) {
                                return {
                                  id: x.id,
                                  data: { ...x.data, name: value },
                                  isUpdated: true,
                                };
                              } else {
                                return { ...x };
                              }
                            }),
                          }));
                        }}
                      />
                    </td>
                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                      <input
                        value={unit.data.size}
                        onChange={(e) => {
                          var value = e.target.value.replace(/\D/, "");
                          this.setState((prevState) => ({
                            units: prevState.units.map((x) => {
                              if (x.id === unit.id) {
                                return {
                                  id: x.id,
                                  data: { ...x.data, size: value },
                                  isUpdated: true,
                                };
                              } else {
                                return { ...x };
                              }
                            }),
                          }));
                        }}
                      />
                    </td>

                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                      <button onClick={() => this.onDelete(unit.id)}>
                        {/* <i className="material-icons deletered">close</i> */}
                        <GrClose />
                      </button>
                    </td>
                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                      {unit.isUpdated && (
                        <button onClick={() => this.onUpdate(unit)}>
                          {/* <i className="material-icons checkgreen">check</i> */}
                          <GrCheckmark />
                        </button>
                      )}
                    </td>

                    <td className="border-grey-light border hover:bg-gray-100 p-3 center">
                      <button
                        style={{ cursor: "pointer" }}
                        onClick={() => this.openImage(unit.data.fileUrl)}
                      >
                        {/* <i className="material-icons">open_in_new</i> */}
                        <GrDocumentPdf className="material-icons" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AdminForm;
