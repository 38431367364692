import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyBm8kA5uom4fvW57yj9Wr-Hyy-syT6L1Ng",
  authDomain: "firemap-28d9b.firebaseapp.com",
  databaseURL: "https://firemap-28d9b.firebaseio.com",
  projectId: "firemap-28d9b",
  storageBucket: "firemap-28d9b.appspot.com",
  messagingSenderId: "59903099050",
  appId: "1:59903099050:web:bcb9b5431e95f6b77e90d3",
};

firebase.initializeApp(config);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const db = firebase.firestore();
const storage = firebase.storage();

export { db, firebase, storage };
