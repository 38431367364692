/*global google*/
import React from "react";
import HelmetSeo from "./components/Helmet/HelmetSeo"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import AdminForm from "./components/AdminForm";
// import BuildingPicker from "./components/buildingPicker";
// import NewAdminForm from "./newAdminForm";
// import NewUserTable from "./newUserTable";
import Warehouses from "./components/Buildings/Warehouses";
import AdminForm from "./components/Buildings/AdminForm";
import BuildingPicker from "./components/Buildings/buildingPicker";
import { db, firebase } from "./firebase";
// import UnportectedTable from "./UnprotectedTable";
import Header from "./components/Header/NewHeader";
import WrappedMap from "./components/WrappedMap/HomeMap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./App.css";
import "./materialize.css";
import { CSSTransition } from "react-transition-group";
import TimeSelect from "./components/Calendar/TimeSelect";
import Directions from "./components/Direction/Directions";
// import Splash from "./components/Splash/Splash";
import PricePreview from "./components/PricePreview/PricePreview";
import Modal from "react-modal";
import UnprotectedTable from "./components/Buildings/UnprotectedTable";
import YakMap from "./components/Map/YakMap";
// import GenerateCouponForm from "./components/Map/generateCoupon"
import HomePage from "./components/HomePage/HomePage";
import Content from "./Content";
import Footer from "./components/Footer/Footer";
if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      directions: null,
      buildingsListLoaded: false,
      UnitsListLoaded: false,
      // hasEntered: false,
      //show a loading message until the database finishes
      isLoaded: false,
      // selectedBuilding: undefined,
      allBuildings: [],
      LoggedIn: false,
      unauthorizedAttempt: false,
      //make a reset button sets to initial viewport and when a user goes back he is
      //taken to a preset viewport of the last locaton he clicked. or will map do this automatically?
      Units: [],
      selectedTime: null,
      selectedDay: null,
      selectedBuildingIndex: 0,
      // userAdress: "",
      // userLat:"",
      // userLong:"",
      hiddenStreet: false,
      addressNumberArray: [],
      togglerIsOpen: false,
    };

    this.unauthorizedAttemptLog = this.unauthorizedAttemptLog.bind(this);
    // this.LoadingMessage=this.LoadingMessage.bind(this)
    this.closeModal = this.closeModal.bind(this);
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  UNSAFE_componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  componentDidMount() {
    // setTimeout(() => {
    //   this.firstVisitNewDevice()
    // }, 5000);

    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(user.email);
        if (
          user.email === "bookkeeping@greystoneassociates.net" ||
          user.email === "bschur@greystoneassociates.net" ||
          user.email === "flex4lease@gmail.com"
        ) {
          // if(true){
          this.setState({ LoggedIn: user });
        } else {
          console.log("someone else tried to sign but he not author");
          this.setState({ unauthorizedAttempt: true });
          this.unauthorizedAttemptLog();
        }
      } else {
        this.setState({ LoggedIn: false });
        this.setState({ unauthorizedAttempt: false });
      }
    });

    // db.collection("buildings").where("isComplete", "==", true)
    db.collection("buildings")
      .get()
      .then((snapshot) => {
        this.setState({ isLoaded: true });
        var buildings = snapshot.docs.map((building) => ({
          id: building.id,
          data: building.data(),
        }));
        // this.setState({ selectedBuilding: buildings[0] })
        this.setState({ allBuildings: buildings });
        this.setState({ buildingsListLoaded: true });
        buildings = this.state.allBuildings;
        buildings.forEach((building) =>
          this.setState({
            addressNumberArray: [
              ...this.state.addressNumberArray,
              building.data.name.split(" ")[0],
            ],
          })
        );
      });

    db.collection("Units")
      .get()
      .then((snapshot) => {
        var newUnits = snapshot.docs.map((item) => ({
          isUpdated: false,
          id: item.id,
          data: item.data(),
        }));
        // this.setState({ units: newUnits })
        // console.log("newUnits", newUnits);
        this.setState({ Units: newUnits });
        this.setState({ UnitsListLoaded: true });
      });

    // console.clear();
    if (firebase.messaging.isSupported()) {


      // function isFacebookApp() {
      //   var ua = navigator.userAgent || navigator.vendor || window.opera;
      //   return (
      //     ua.indexOf("FBAN") > -1 ||
      //     ua.indexOf("FBAV") > -1 ||
      //     ua.indexOf("Instagram") > -1
      //   );
      // }
      // if (isFacebookApp()) {
      //   // your action here if user using Facebook in-app browser
      //   alert(
      //     "Are you using Facebook or instagram browser? You wont be able to get directions or view spaceplans, many of leasewarehouses features will not work, for a better experience, try pressing [...] in the upper right to open in the browser of your choice. Thank you!"
      //   );

      // } else {

      const messaging = firebase.messaging();
      let flag = true;
      var loaded = parseInt(localStorage.getItem("loaded"), 10),
        loaded_numb = loaded ? loaded + 1 : 1;
      localStorage.setItem("loaded", loaded_numb);
      // console.log("been here times = ", loaded_numb);

      if (loaded_numb === 3) {
        // messaging
        //   .requestPermission()
        // .then(() => {})
        Notification.requestPermission()
          .then(function () {
            console.log("have permission");
            return messaging.getToken();
          })
          .then((token) => {
            console.log("token returned= ", token);
            localStorage.setItem("browserInstance", token);
            if (flag) {
              db.collection("subscribers")
                .add({
                  browserToken: token,
                })
                .then(() => {
                  console.log("token saved to the database");
                  return;
                })
                .catch((err) => {
                  console.error(err, "= was an err in adding token to db");
                });
            }
          })
          .catch(function (error) {
            console.log(
              "error in running requestpermission method on the messaging object=",
              error
            );
          });
      } else {
        console.log(
          "hes been here less than or more than three times, no need to ask permission or add him to the database"
        );
      }

      messaging.onMessage(function (payload) {
        console.log(payload, "= payload message");
      });

    }
    // }

    //end of component did mount lifecycle method
    window.prerenderReady = true;

  }

  // firstVisitNewDevice(){
  //   if(localStorage.firstVisit){
  //     console.log('fdias device called')
  //     return null
  //   } else {
  //     console.log('fdias device called')

  //     localStorage.firstVisit=true;
  //     this.setState({ modalIsOpen: true });
  //   }
  //  }

  // handleEnter = () => {
  //   this.setState({ hasEntered: true });
  // };

  unauthorizedAttemptLog() {
    if (localStorage.examplethingexists) {
      return null;
    } else {
      localStorage.examplethingexists = true;
      toast.warn("you'r not authorized to list buildings or units", {
        position: "bottom-right",
      });
    }
  }

  setSelectedTime = (selectedTimeIndex) => {
    this.setState({ selectedTime: selectedTimeIndex });
  };

  setSelectedDay = (selectedDayIndex) => {
    this.setState({ selectedDay: selectedDayIndex });
  };

  handleSelectedIndex = (selectedBuildingIndex) => {
    // console.log('handleSelected Index was called inside of App component ')
    this.setState({ selectedBuildingIndex: selectedBuildingIndex });
  };

  closeModal() {
    console.log("requested to close the modal");
    this.setState({ modalIsOpen: false });
  }

  hideStreet = () => {
    this.setState({ hiddenStreet: false });
  };

  toggleStreet = () => {
    console.log("now toggle street");
    this.setState((prevState) => ({
      hiddenStreet: !prevState.hiddenStreet,
    }));
    console.log(this.state.hiddenStreet);
  };

  openDirectionsToggler = () => {
    this.setState({ togglerIsOpen: true });
  };

  closeDirectionsToggler = () => {
    this.setState({ togglerIsOpen: false });
  };

  concatBuildings = () => {
    let buildingStr = ""
    this.state.allBuildings.forEach((building, idx) => buildingStr += " " + Number(idx + 1) + ") " + building.data.name + " - " + building.data.seo + ".")
    return buildingStr
  }

  render() {

    // console.log(window.location.href, "=  $$  href in wind")
    // console.log(window.location.pathname, "= $$ pathname");
    // console.log(window.location, "= location")
    // let { hasEntered } = this.state;


    // console.log(hasEntered, "hasEntered");

    // if (!hasEntered) {
    //   return <HomePage someprop={"i am some prop"} enter={this.handleEnter} />;
    // } else 
    console.log(this.state, "the state in app")

    if ((!this.state.buildingsListLoaded || !this.state.UnitsListLoaded)) {
      return (
        // <CSSTransition
        //   in={true}
        //   appear={true}
        //   exit={true}
        //   //set timeout being ignored
        //   timeout={1000}
        //   classNames="fade"
        // >
        //   <Splash />
        // </CSSTransition>
        <div></div>
      );
    } else {
      //he has entered and everything is loaded now show the map
      // console.log(this.state.selectedBuildingIndex, "=selected building index")

      return (
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
          // style={customStyles}
          >
            this is some lorum ipsum content etc... about the site on first
            visit
          </Modal>

          <CSSTransition
            in={true}
            appear={true}
            //set timeout being ignored
            timeout={7000}
            classNames="fade"
          >
            <Router>
              <Content hideStreet={this.hideStreet} />
              <ToastContainer />
              <Switch>
                <Route
                  exact
                  path="/calendar"
                  render={() => (
                    <>
                      <HelmetSeo shortTitle={"Schedule a showing to Lease Warehouses"} type={"image/jpeg"} longTitle={"Book a showing to see commercial space for lease"} description={"Schedule a showing with one of our agents to view commercial spaces for lease"} />
                      <Header
                        selectedBuildingIndex={this.state.selectedBuildingIndex}
                        addressNumberArray={this.state.addressNumberArray}
                        inUnauthorized={this.state.unauthorizedAttempt}
                        in={this.state.LoggedIn}
                        // setToCard={this.setToCard}
                        tourNeeded={false}
                      />
                      <TimeSelect
                        selectedDay={this.setSelectedDay}
                        selectedTime={this.setSelectedTime}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/buildings"
                  render={() => (
                    <>
                      <HelmetSeo shortTitle={"list of all of the industrial buildings on lease warehouses"} type={"image/jpeg"} longTitle={"The list of all of the warehouses for lease on lease warehouses"} description={"Our current commercial buildings:" + this.concatBuildings()} />
                      <Header />
                      <Warehouses allBuildings={this.state.allBuildings} />
                      <Footer style={{}} />
                    </>
                  )}
                ></Route>
                <Route
                  exact
                  path="/addBuilding"
                  render={({ match }) => (
                    <>
                      <Header
                        // selectedBuildingIndex={this.state.selectedBuildingIndex}
                        // addressNumberArray={this.state.addressNumberArray}
                        inUnauthorized={this.state.unauthorizedAttempt}
                        in={this.state.LoggedIn}
                        // setToCard={this.setToCard}
                        tourNeeded={false}
                      />
                      {this.state.LoggedIn ? (
                        <YakMap options={{ disableDefaultUI: true }} />
                      ) : (
                          <Unauthorized />
                        )}
                    </>
                  )}
                />
                <Route
                  exact
                  path="/warehouse/:id"
                  render={({ match }) => (
                    <>
                      <HelmetSeo
                        shortTitle={this.state.allBuildings[
                          this.state.selectedBuildingIndex
                        ].data.name + " " + this.state.allBuildings[this.state.selectedBuildingIndex].data.seo}
                        longTitle={"Industrial warehouses for lease"}
                        description={this.state.allBuildings[this.state.selectedBuildingIndex].data.description}
                        url={"https://leasewarehouses.net/warehouse/" + this.state.allBuildings[
                          this.state.selectedBuildingIndex
                        ].data.name.split(' ').join('_')}
                        image={this.state.allBuildings[
                          this.state.selectedBuildingIndex
                        ].data.fileUrl}
                      />
                      <Header
                        selectedBuildingIndex={this.state.selectedBuildingIndex}
                        addressNumberArray={this.state.addressNumberArray}
                        inUnauthorized={this.state.unauthorizedAttempt}
                        in={this.state.LoggedIn}
                        // setToCard={this.setToCard}
                        tourNeeded={true}
                        toggleStreet={this.toggleStreet}
                        isHidden={this.state.hiddenStreet}
                      />
                      <WrappedMap
                        hiddenStreet={this.state.hiddenStreet}
                        // setToCard={this.setToCard}
                        buildings={this.state.allBuildings}
                        units={this.state.Units}
                        onSelectBuildingIndex={this.handleSelectedIndex}
                        {...this.state}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/"
                  render={({ match }) => (
                    <>
                      <HelmetSeo shortTitle={"Lease Warehouses"}
                        longTitle={"Lease Warehouses, Lease Industrial and Commercial Spaces"} description={"Available Warehouses for lease on leasewarehouses.net  Find Warehouse listings, Industrial and commercial listings, or find a broker specializing in Warehouses leases."}
                        image={"https://leasewarehouses.net/images/homepage.jpg"}
                        type={"image/jpeg"}
                      />
                      <HomePage />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/directions"
                  render={() => (
                    <>
                      <HelmetSeo shortTitle={"Get Directions to Warehouses"}
                        longTitle={`Directions to ${this.state.allBuildings[
                          this.state.selectedBuildingIndex
                        ].data.name}`} description={"Get driving directions to Warehouses, flex, industrial, commercial, and manufacturing spaces for lease"}
                        type={"image/jpeg"}
                      />
                      <Header
                        togglerIsOpen={this.state.togglerIsOpen}
                        openDirectionsToggler={this.openDirectionsToggler}
                        closeDirectionsToggler={this.closeDirectionsToggler}
                        inUnauthorized={this.state.unauthorizedAttempt}
                        in={this.state.LoggedIn}
                        // setToCard={this.setToCard}
                        selectedBuildingIndex={this.state.selectedBuildingIndex}
                        tourNeeded={false}
                        onSelectBuildingIndex={this.handleSelectedIndex}
                        {...this.state}
                      />
                      <Directions
                        // setToCard={this.setToCard}
                        openDirectionsToggler={this.openDirectionsToggler}
                        buildings={this.state.allBuildings}
                        selectedBuildingIndex={this.state.selectedBuildingIndex}
                        // userAdress={this.state.userAdress}
                        {...this.state}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/pricepreview"
                  render={() => (
                    <>
                      <HelmetSeo shortTitle={"Application for commercial lease"}
                        longTitle={`Leasing application for ${this.state.allBuildings[
                          this.state.selectedBuildingIndex
                        ].data.name}`} description={`Submit an application for a commercial space for lease, and recieve notifications reguarding upcoming appointments at ${this.state.allBuildings[
                          this.state.selectedBuildingIndex
                        ].data.name}`}
                        type={"image/jpeg"}
                      />
                      <Header
                        selectedBuildingIndex={this.state.selectedBuildingIndex}
                        addressNumberArray={this.state.addressNumberArray}
                        inUnauthorized={this.state.unauthorizedAttempt}
                        in={this.state.LoggedIn}
                        // setToCard={this.setToCard}
                        tourNeeded={false}
                      />
                      <PricePreview
                        buildings={this.state.allBuildings}
                        selectedBuildingIndex={this.state.selectedBuildingIndex}
                        onSelectBuildingIndex={this.handleSelectedIndex}
                        {...this.state}
                      />
                    </>
                  )}
                />
                <Route
                  exact
                  path="/buildings/:id/:slug"
                  render={({ match }) => (
                    <>
                      <HelmetSeo shortTitle={`floor plans for ${this.state.allBuildings[
                        this.state.selectedBuildingIndex
                      ].data.name}`}
                        longTitle={"commercial building floor plans"}
                        description={`Floor plans for ${this.state.allBuildings[
                          this.state.selectedBuildingIndex
                        ].data.name} a building featuring ${this.state.allBuildings[
                          this.state.selectedBuildingIndex
                        ].data.seo}`}
                        url={
                          "https://leasewarehouses.net/buildings/" + this.state.allBuildings[
                            this.state.selectedBuildingIndex
                          ].id + "/" + this.state.allBuildings[
                            this.state.selectedBuildingIndex
                          ].data.name.split(' ').join('_')
                        }
                        image={"https://leasewarehouses.net/images/" + this.state.allBuildings[
                          this.state.selectedBuildingIndex
                        ].data.name.split(' ').join('_') + ".jpg"}
                        type={"image/jpeg"}
                      />
                      <Header
                        selectedBuildingIndex={this.state.selectedBuildingIndex}
                        addressNumberArray={this.state.addressNumberArray}
                        inUnauthorized={this.state.unauthorizedAttempt}
                        in={this.state.LoggedIn}
                        // setToCard={this.setToCard}
                        tourNeeded={true}
                      />
                      <div className="building-container-centered m-5">
                        <>
                          {this.state.isLoaded ? (
                            <>
                              <BuildingPicker
                                allBuildings={this.state.allBuildings}
                                selectedBuilding={match}
                                onSelectBuildingIndex={this.handleSelectedIndex}
                                index={this.state.selectedBuildingIndex}
                              />
                              {this.state.LoggedIn ? (
                                <AdminForm selectedBuilding={match} />
                              ) : (
                                  <UnprotectedTable desc={this.state.allBuildings[this.state.selectedBuildingIndex].data.description} selectedBuilding={match} />
                                )}
                            </>
                          ) : (
                              <p>Loading...</p>
                            )}
                        </>
                      </div>
                    </>
                  )}
                />
                <Route path="*" component={NotPageFound} />
              </Switch>
            </Router>
          </CSSTransition>
        </div>
      );
    }
  }
}
//buildingPicker has to call ChangeSelectedBuilding when the user makes a selection of building
export default App;

function NotPageFound() {
  return (
    <h1 style={{ fontSize: "2.5rem", textAlign: "center", marginTop: "2rem" }}>
      404 Page Not Found....!
    </h1>
  );
}

function Unauthorized() {
  return (
    <h1 style={{ fontSize: "2.5rem", textAlign: "center", marginTop: "2rem" }}>
      You are not Authorized to use this feature. Please sign in first if you have a password, or contact management.  <br />847-982-0340
    </h1>
  );
}
