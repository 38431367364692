import React, { useState, useEffect } from "react";
import {
  DirectionsService,
  DirectionsRenderer,
  Polyline,
} from "@react-google-maps/api";
import { Map } from "../MapGlobal";
import { toast } from "react-toastify";

import Geocode from "react-geocode";
let GoogleMapsAPI = "AIzaSyC2K_KUrF4oY_1jK1le1fGcnRsxgjDSFNc";
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

const Direction = ({
  selectedBuildingIndex,
  buildings,
  setUserLatLong,
  setadress,
}) => {
  const [destination, setDestination] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [response, setResponse] = useState(null);
  const [path, setPath] = useState(null);

  const [zoom, setZoom] = useState(15);
  const [doFetchDirection, setDoFetchDirection] = useState(true);
  const notify = () =>
    toast("Too far from chicago? Perhaps take a flight and come visit us? ✈️ ");

  useEffect(() => {
    console.log("Hello from useEffect! only one");
  }, []);

  useEffect(() => {

    if (!navigator.geolocation) {
      window.alert("geolocation not allowed");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setOrigin({ lat, lng });
          setUserLatLong({ lat, lng });
          Geocode.fromLatLng(lat, lng).then(
            (response) => {
              const address = response.results[0].formatted_address;
              if (address) {
                setadress(address);
              }
            },
            (error) => {
              console.error(error);
            }
          );
        },
        (err) => {
          console.error(err);
          setZoom(9);
          toast(err.message, { autoClose: 5000, });
          function isFacebookApp() {
            var ua = navigator.userAgent || navigator.vendor || window.opera;
            return (
              ua.indexOf("FBAN") > -1 ||
              ua.indexOf("FBAV") > -1 ||
              ua.indexOf("Instagram") > -1
            );
          }
          if (isFacebookApp()) {
            // your action here if user using Facebook in-app browser
            toast("Using facebook or instagram? it does not support Geolocation , click the three dots (...) in the upper right or lower left to open in your devices normal web browser to use this feature", { autoClose: 13000, });
          }
        }
      );
    }
  }, [setUserLatLong, setadress]);

  useEffect(() => {
    try {
      const lat = buildings[selectedBuildingIndex].data.location.latitude;
      const lng = buildings[selectedBuildingIndex].data.location.longitude;
      setDestination({ lat, lng });
      setDoFetchDirection(true);
    } catch (error) {
      // console.error(error);
    }
  }, [buildings, selectedBuildingIndex]);

  const pathOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,

    zIndex: 1,
  };
  // console.clear()
  //  console.log("origin Directions=", origin);
  return (
    <div style={{ height: "300px" }}>
      <Map zoom={zoom}>
        {doFetchDirection && (
          <DirectionsService
            // required
            options={{
              destination: destination,
              origin: origin,
              travelMode: "DRIVING",
            }}
            // required
            callback={(response) => {
              // console.log(response, 'response in directions 3');
              if (response !== null) {
                if (response.status === "OK") {
                  // console.log("response =", response);
                  setResponse(response);
                } else {
                  // console.log("response: ", response);
                  setPath([origin, destination]);
                  setZoom(3);
                }
              }
              setDoFetchDirection(false);
              response === null &&
                window.alert(
                  "something went wrong check your internet connection and try again"
                );
            }}
          />
        )}

        {response !== null && (
          <DirectionsRenderer
            // required
            options={{
              directions: response,
            }}
          />
        )}
        {path && (
          <Polyline onLoad={() => notify()} path={path} options={pathOptions} />
        )}
      </Map>
    </div>
  );
};

export default Direction;
