import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebase } from "../../firebase";

// the below instructions need revison/clarification as firebase auth is not
//working

// Find where we get user data from firebase auth and then set the signed in state.
// Change it to check if the user's email is in a hardcoded list instead.
// If it is in the list the state should be set to signed in.
// If not the state should not be changed and then user should be redirected to a new page
// where you say that thing about signup being available soon
// (to be secure we'll add three lines making the same check on the server soon)

class SignInScreen extends React.Component {
  // Configure FirebaseUI.
  uiConfig = {
    credentialHelper: "none", // disable redirect to accountchooser.com account chooser lets them auto fill their usernames

    // Popup signin flow rather than redirect flow.
    // We will display Google and Facebook as auth providers.
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  // Listen to the Firebase Auth state and set the local state.

  //   Walking through different possible values, say the user hasn't signed in yet.
  //   They won't be signed in, but they also won't be unauthorized, so isSignedIn||inUnathorized
  //   will be true.
  // inUnathorized only means something if the user is signed in, I believe
  render() {
    console.log("the prop for signedIn");
    console.log(this.props.isSignedIn);
    // if (this.props.isSignedIn||this.props.inUnauthorized) {
    //   return (
    //     <>
    //     <div className="mdl-card mdl-shadow--2dp firebaseui-container firebaseui-id-page-sign-in">
    //       <div className="firebaseui-card-header">
    //       <h1 className="firebaseui-title">Sign Out</h1>
    //       </div>

    //       <div className="firebaseui-card-content">
    //       <div className="firebaseui-relative-wrapper">
    //       <p>Currently you are signed in. Do you wish to Sign Out?</p>
    //       </div>
    //       </div>

    //       <div className="firebaseui-card-actions">
    //         <div className="firebaseui-form-actions">
    //         <button className="firebaseui-id-submit firebaseui-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored" onClick={() => firebase.auth().signOut()}>Sign-out</button>
    //         </div>
    //       </div>
    //     </div>
    //     </>

    //   );
    // } else {
    //   return (
    //     <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
    //   );

    // }

    return (
      <StyledFirebaseAuth
        uiConfig={this.uiConfig}
        firebaseAuth={firebase.auth()}
      />
    );
  }
}

export default SignInScreen;
