import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Marker, MarkerClusterer, InfoBox } from "@react-google-maps/api";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { db } from "../../firebase";
// import LoadingBar from "../LoadingBar"
import { Map } from "../MapGlobal";
import { storage } from "../../firebase";
import loopneticon from "./loopneticon.png";
import Styles from "./mapStyles.js";
import GenerateCouponForm from "./generateCoupon";

const firebase = require("firebase");

const containerStyle = {
  width: "100%",
  height: "100vh",
};
const mapOptions = {
  disableDoubleClickZoom: true, // <---
};
const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
};

const infoOptions = { closeBoxURL: "", enableEventPropagation: true };
const infoCenter = {
  lat: 33.772,
  lng: -117.214,
};

const nyc = {
  lat: 40.75937,
  lng: -73.9899701,
};

const chicago = {
  lat: 41.8333925,
  lng: -88.0121478,
};

class YakMap extends Component {
  constructor() {
    super();
    this.state = {
      center: chicago,
      buildings: [],
    };
  }

  createKey(location) {
    // console.log("create key is run with location", location);
    return location.lat + location.lng + Math.floor(Math.random() * 100);
  }

  componentDidMount() {
    // db.collection("YakBuildings")
    console.log("props in YakMap componentDidmount", this.props);
    db.collection("buildings")
      .get()
      .then((snapshot) => {
        var Buildings = snapshot.docs.map((item) => ({
          // data: item.data(),
          lat: item.data().location._lat,
          lng: item.data().location._long,
          key: item.id,
        }));
        this.setState({ buildings: Buildings });
      });
  }

  onLoad = (ref) => (this.searchBox = ref);
  onMarkerLoad = (marker) => {
    console.log("marker: ", marker);
  };

  onInfoBoxLoad = (infoBox) => {
    console.log("infoBox: ", infoBox);
  };

  onPlacesChanged = () => {
    let location = this.searchBox.getPlaces()[0].geometry.location;
    console.log("onPlacesChanged", location);
    let newCenter = {
      lat: location.lat(),
      lng: location.lng(),
    };
    this.setState({ center: newCenter });

    let latitude = location.lat();
    let longitude = location.lng();

    if (this.state.buildings.find((building) => building.lat === latitude)) {
      return window.alert(
        "Can not add building, a building already exists at this location. choose a different address"
      );
    }

    this.setState({ newlyAddedBuiliding: { lat: latitude, lng: longitude } });
    console.log(this.state);
    return this.setState({
      buildings: [...this.state.buildings, { lat: latitude, lng: longitude }],
    });
  };

  centerCheckIsCentered = (location) => {
    location.lat === this.state.center.lat &&
    location.lng === this.state.center.lng
      ? this.props.history.push(
          `/addBuilding?${location.lat}&${location.lng}&${location.key}`
        )
      : this.setState({ center: { lat: location.lat, lng: location.lng } });
  };

  addBuilding = () => {
    console.log("herer you will add a buildling");
    let latitude = this.searchBox.getPlaces()[0].geometry.location.lat();
    let longitude = this.searchBox.getPlaces()[0].geometry.location.lng();
    let data = {
      location: new firebase.firestore.GeoPoint(latitude, longitude),
      isComplete: false,
    };

    // db.collection("YakBuildings")
    db.collection("buildings")
      .add(data)
      .then((snapshot) => {
        let id = snapshot.id;
        console.log("the id snapshot is", id);
        console.log(this.searchBox.getPlaces()[0].formatted_address);
        let formatted_address = this.searchBox.getPlaces()[0].formatted_address;
        window.alert(formatted_address + " was added as a building");
      });
  };
  render() {
    // console.log(this.props, "this.props");
    let params = this.state.newlyAddedBuiliding
      ? this.state.newlyAddedBuiliding.lat +
        "&" +
        this.state.newlyAddedBuiliding.lng
      : "nothing";
    return (
      <Map
        onLoad={(ref) => (this.map = ref)}
        options={mapOptions}
        disableDoubleClickZoom={true}
        mapContainerStyle={containerStyle}
        center={this.state.center}
        // onDragEnd={()=>this.setState({center:{ lat: null, lng: null }})}
        // onDragEnd={()=>console.log(map.getCenter())}
        onDragEnd={() => {
          if (this.map) {
            const center = this.map.getCenter();
            this.setState({ center: { lat: center.lat(), lng: center.lng() } });
          } else console.log("no mapref");
        }}
        zoom={10}
        // this smoothPan prop is not working
        smoothPan={true}
      >
        <StandaloneSearchBox
          onLoad={this.onLoad}
          onPlacesChanged={this.onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Add a Building for Lease"
            style={Styles.placeHolder}
          />
        </StandaloneSearchBox>
        <MarkerClusterer options={options}>
          {(clusterer) =>
            this.state.buildings.map((location) => (
              <Marker
                onClick={() => this.centerCheckIsCentered(location)}
                // onDblClick={() => { this.props.history.push(`/addBuilding?${location.lat}&${location.lng}`) }}
                // onClick={(e)=>{console.log('click fired',e)}}
                // onDblClick={(e)=>{console.log('double click fired', e)}}
                key={this.createKey(location)}
                position={location}
                clusterer={clusterer}
              />
            ))
          }
        </MarkerClusterer>
      </Map>
    );
  }
}

export default withRouter(YakMap);
