/*global google*/
import React, {
  useState,
  // useEffect, useRef 
} from "react";
import { Link } from "react-router-dom";
import { DirectionStyles } from "./DirectionStyles";
import { Map } from "../MapGlobal";
import Footer from "../Footer/Footer";
import { MdDirectionsCar } from "react-icons/md";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import RenderDirectionsComponent from "./RenderDirectionsComponent";
const sidebarStyle = {
  width: "42%",
  float: "left",
  overflow: "scroll",
  // "height":"calc(100vh - 64px)"
  height: "100vh",
};
const mapStyles = { width: "55%", marginRight: "2%", float: "left" };
const hiddenStyle = {
  display: "none",
};
const drivingToggler = {
  background: "none rgb(255, 255, 255)",
  position: "absolute",
  top: "74px",
  left: "189px",
  userSelect: "none",
  borderRadius: "2px",
  // height: "50px",
  // width: "50px",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
  opacity: "0.9",
};

const chevRonStyle = {
  // width: "1.7em",
  // height: "1.9em",
  fontSize: "xxx-large",
  // paddingLeft: "5px",
  color: "navy",

};

const infoPanelStyle = {
  position: "absolute",
  top: "130px",
  width: "50%",
  height: "65%",
  marginLeft: "7px",
  overflow: "auto",
  bordeRadius: "3px",
  opacity: ".9",
  backgroundColor: "rgb(255,255,255)",
  boxShadow: "0px 3px 8px rgb(100,100,100)",
  direction: "ltr",
};

const buttonStyle = {
  background: "none rgb(255, 255, 255)",
  border: "0px",
  position: "absolute",
  top: "74px",
  left: "10px",
  cursor: "pointer",
  userSelect: "none",
  borderRadius: "2px",
  height: "50px",
  width: "40px",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
  opacity: ".9",
  direction: "ltr",
  overflow: "hidden",
  textAlign: "center",
  fontSize: "18px",
  padding: "0px 0px",
};

const MapWDirection = ({
  selectedBuildingIndex,
  buildings,
  setDirections,
  setCitizenshipStatus,
}) => {
  const [map, setMap] = useState(false);
  const [zoom, setZoom] = useState(15);
  const [loaded, isLoaded] = useState(false);
  // const screenWidth=window.innerWidth
  // const returnPosition=()=>{
  //   return screenWidth>410?window.google.maps.ControlPosition.TOP_RIGHT:window.google.maps.ControlPosition.BOTTOM_LEFT
  // }
  const onLoad = (ref) => {
    setMap(true);
    isLoaded(true);
  };

  return (
    <div style={{ height: "100vh", visibility: map ? "visible" : "hidden" }}>
      <Map
        onLoad={(ref) => onLoad(ref)}
        options={
          loaded &&
          window.google && {
            fullscreenControl: false,
            mapTypeControlOptions: {
              style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              // position: returnPosition(),
              position: window.google.maps.ControlPosition.BOTTOM_LEFT,
            },
          }
        }
        zoom={zoom}
      >
        <RenderDirectionsComponent
          setZoom={setZoom}
          setCitizenshipStatus={setCitizenshipStatus}
          setDirections={setDirections}
          buildings={buildings}
          selectedBuildingIndex={selectedBuildingIndex}
        />
      </Map>
    </div>
  );
};

const Directions = ({
  setToCard,
  selectedBuildingIndex,
  buildings,
  openDirectionsToggler,
}) => {
  const [directions, setDirections] = useState(null);

  const [citizen, setCitizenshipStatus] = useState(true);

  const [sidebar, setSideBar] = useState(false);

  const handleSidebar = () => {
    return sidebar ? setSideBar(false) : setSideBar(true);
  };

  return (
    <div className="directions-container">
      <div
        className="map-container"
        // style={{height:"100vh"}}
        style={sidebar ? mapStyles : null}
      >
        <MapWDirection
          setDirections={setDirections}
          setCitizenshipStatus={setCitizenshipStatus}
          selectedBuildingIndex={selectedBuildingIndex}
          buildings={buildings}
        />

        {!citizen && (
          <Link to={`/calendar`}>
            <div
              className="schedule-btn non-citizen"
              style={DirectionStyles.scheduleBtnStyle}
            >
              Schedule
            </div>
          </Link>
        )}

        {directions && (
          <>
            <button style={buttonStyle} onClick={() => handleSidebar()}>
              <span style={null}>
                {sidebar ? (
                  <FaChevronLeft style={chevRonStyle} />
                ) : (
                    <FaChevronRight style={chevRonStyle} />
                  )}
              </span>
            </button>
            {!sidebar && (
              <Link to={`/calendar`}>
                <div
                  className="schedule-btn"
                  style={DirectionStyles.scheduleBtnStyle}
                >
                  Schedule
                </div>
              </Link>
            )}
          </>
        )}

        {directions && !sidebar && (
          <button
            style={drivingToggler}
            onClick={() => openDirectionsToggler()}
          >
            <MdDirectionsCar
              style={{
                margin: "auto",
                fontSize: "x-large",
                color: "lightslategrey",
              }}
            />
            <span className="changebuild" style={{ fontSize: "18px", fontWeight: 600, padding: "4px" }}>Change Building</span>
          </button>
        )}
      </div>

      <div
        style={
          directions && sidebar
            ? sidebarStyle
            : directions
              ? infoPanelStyle
              : hiddenStyle
        }
        id="panel"
      ></div>

      {sidebar ? <div className="clearfix"></div> : null}
      <Footer style={null} />
    </div>
  );
};

export default Directions;
