import React, { Component } from "react";
import { storage } from "../../firebase";

//this componenent needs to know from the other one, when to set url state to false
//so that the image disappears after upload
class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      url: "",
      progress: 0,
      pdf: false,
    };
    // this.handleUpload=this.handleUpload.bind(this)
    // this.handleUpload=this.handleChange.bind(this)
  }

  clearLink = () => {
    this.setState({ url: "", progress: 0 });
  };

  handleUpload = (e) => {
    e.preventDefault();
    const { image } = this.state;
    //what this means is create a file on the server with this path the last
    //parameter is the name prop on the img user chooses, then set a callback
    //for putting the entire img object in the bucket, it is a 'task'
    if (image) {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          //progress...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              console.log(url);
              this.setState({ url });
              this.props.onUpload(url);
            });
        }
      );
    } else {
      window.alert("must add an image");
    }
    // we will see if the below functions like a middleware. the first is progress
    // uploadTask.on('state_changed', progress, error, complete)
  };

  handleChange = (e) => {
    console.log(e.target);
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
      console.log("image type :", image.type);
      if (image.type === "application/pdf") {
        window.alert(
          "pdf file format detected, you may upload this file, just know you wont be able to see a preview of it"
        );
        this.setState({ pdf: true });
      } else {
        this.setState({ pdf: false });
      }
    }
  };

  setSrc = () => {
    return this.props.idInLink && !this.state.url
      ? this.props.idInLink
      : !this.state.url
      ? "http://via.placeholder.com/400x300"
      : this.state.url && !this.state.pdf
      ? this.state.url
      : "https://img.favpng.com/13/15/11/pdf-computer-icons-adobe-acrobat-png-favpng-U7uZyhQx2jtkG6wVc0xvUnX1W.jpg";
    // console.log(this.props.idInLink, 'this.props.idInLink');
    // console.log(this.state.url, 'this.state.url');
  };

  render() {
    const style = {
      // height: '100vh',
      // display: 'flex',
      marginTop: "10vh",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    };

    return (
      <div style={style}>
        <progress value={this.state.progress} max="100" />
        {/* <br /> */}
        <input
          type="file"
          accept=".jpg,.jpeg,.pdf"
          onChange={this.handleChange}
          id="fileUpload"
        />

        {/* <br /> */}
        <img
          style={{ borderRadius: "2px" }}
          //  src={this.state.url || 'http://via.placeholder.com/400x300'}
          // src={(this.props.idInLink&&this.props.idInLink!=='/assets/building_photos/undefined.jpg')
          //     ?this.props.idInLink
          //     :!this.state.url
          //     ?'http://via.placeholder.com/400x300'
          //     :this.state.url&&!this.state.pdf?this.state.url
          //     :'https://img.favpng.com/13/15/11/pdf-computer-icons-adobe-acrobat-png-favpng-U7uZyhQx2jtkG6wVc0xvUnX1W.jpg'}
          src={this.setSrc()}
          alt="Uploaded images"
          height="300"
          width="400"
        />
        <button className="btn-large my-5" onClick={this.handleUpload}>
          <i className="material-icons">cloud_upload</i>
        </button>
      </div>
    );
  }
}

export default ImageUpload;
