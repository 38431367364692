import React from "react";
import { Link } from "react-router-dom";
import "./warehousespage.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const Warehouses = ({ allBuildings }) => {
  return (
    <TransitionGroup>
      <CSSTransition
        in={true}
        appear={true}
        exit={true}
        //set timeout being ignored
        timeout={1000}
        classNames="fade"
      >

        <div
          style={{
            textAlign: "center",
          }}
        >
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              paddingLeft: "10px",
              paddingRight: "10px"
            }}
          >
            {" "}
        Lease Warehouses and Industrial Spaces at these Buildings
      </h1>
          <ul
            style={
              {
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "space-between",
                fontSize: "large",
                // marginBottom: "50px"
              }
            }
          >
            {allBuildings.map((building) => (
              <Link
                style={{ padding: "5px" }}
                key={building.id}
                to={
                  "/buildings/" +
                  building.id +
                  "/" +
                  building.data.name.split(" ").join("_")
                }
              >
                <li style={{ fontSize: "larger" }} className="linkHover" key={building.id} value={building.id}>
                  {building.data.name}
                </li>
              </Link>
            ))}
          </ul>
        </div >





      </CSSTransition>

    </TransitionGroup>


  );
};

export default Warehouses;
