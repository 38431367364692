import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const GenerateCouponForm = () => {
  const [email, setEmail] = useState("");
  const [valid, setInvalid] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const flag = true;

  useEffect(() => {}, []);

  const genCode = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const sendCode = async (code) => {
    const getStatus = (message) => {
      const status = JSON.parse(message)[0].statusCode;
      if (status >= 200 && status < 300) {
        // window.alert('your coupon was mailed out and added to the db')
        toast.success("your coupon was successfully created and sent", {
          position: "bottom-right",
        });
      } else {
        toast.error(
          "there was a problem sending your coupon, please retry again or contact the system administrator",
          {
            position: "bottom-right",
          }
        );
      }
    };
    // console.log('I will call a cloud function w cod ', code)
    try {
      const res = await fetch(
        "https://us-central1-firemap-28d9b.cloudfunctions.net/mailCouponCode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(code),
        }
      );
      const message = await res.text();
      message ? getStatus(message) : console.log("no message");
      setDisabled(false);
    } catch (error) {
      console.error(error);
    }
  };

  const addRecord = (code, email) => {
    //Below I need to set disabled to prevent sending unintended multiple emails
    console.log("email going to addRecord=", email);

    db.collection("Coupons")
      .add({
        generatedCouponCode: code,
        email: email,
      })
      .then((snapshot) => {
        let id = snapshot.id;
        console.log("Coupon id", id);
        let codeObj = {};
        codeObj.generatedCouponCode = code;
        codeObj.email = email;
        if (flag) {
          sendCode(codeObj);
        }
      })
      .catch((err) => {
        console.error(err, "error in adding record to dby");
        return;
      });
  };

  const generateCouponCode = () => {
    // console.log('i will gen a randCode')
    setDisabled(true);
    toast.warn(
      "generating coupon code, adding record and emailing out coupon, processing.. please wait",
      {
        position: "bottom-right",
      }
    );
    var code = genCode(15);
    addRecord(code, email);
  };

  const handeEmailChange = (e) => {
    setInvalid(true);
    const value = e.target.value;
    // console.log(value, "value")
    setEmail(value);
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    // console.log('the email is ', email)
    return re.test(email);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // console.log('he tried to submit')
    validateEmail(email) ? generateCouponCode(email) : setInvalid(false);
  };

  return (
    <>
      <ToastContainer />

      <div
        // className="mx-10 md:mx-20 lg:mx-32 xl:mx-40 mt-10"
        // className="bg-white shadow-md rounded px-8 pt-6 pb-8 my-12"

        style={{}}
      >
        <form
          // className="mx-10 md:mx-20 lg:mx-32 xl:mx-40 mt-10"
          // className="bg-white shadow-md rounded px-8 pt-6 pb-8 my-12"
          onSubmit={(e) => handleEmailSubmit(e)}
        >
          <div className="mb-4">
            {!valid && (
              <p style={{ fontSize: "1em", color: "red", textAlign: "left" }}>
                Submitted an invalid email try again.
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email:
            </label>
            <input
              style={{ fontSize: "20px" }}
              className="shadow appearance-none border rounded  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={email}
              onChange={(e) => handeEmailChange(e)}
              type="email"
              placeholder="email adress of coupon recipient"
            />
          </div>

          <div className="mb-4">
            <button
              // onClick={() => { generateCouponCode() }}
              disabled={disabled}
              className="btn"
              type="submit"
            >
              Submit!
            </button>
            <p
              style={{
                float: "right",
                color: "#0000806e",
                fontSize: ".9em",
              }}
            >
              *Coupons expire in 24 hours
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

//make three alerts, one when the button is disabled. waiting, two when its error, three when its success.

export default GenerateCouponForm;
