import React from "react";

import { db } from "../../firebase";
import Footer from "../Footer/Footer";
import { CSSTransition } from "react-transition-group";
import Splash from "../Splash/Splash";
import { GrDocumentPdf } from "react-icons/gr";

class UnprotectedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      selectedBuilding: undefined,
      isFetched: false,
    };
  }

  componentDidMount() {
    // console.log('componentdidMount lifecycle method')
    db.collection("Units")
      .get()
      .then((snapshot) => {
        var newUnits = snapshot.docs.map((item) => ({
          id: item.id,
          data: item.data(),
        }));
        this.setState({ units: newUnits });
        window.newUnits = newUnits;
        // console.log('the props of the unprotectedTable :', this.props)

        /*uncomment below to test splash*/
        var self = this
        setTimeout(function () {
          self.setState({ isFetched: true });
        }, 700
        );

        // this.setState({isFetched:true})


      });
  }
  downloadImage = () => {
    console.log("tried to download an image");
    // storageRef.getDownloadURL().then(function(url) {
    // console.log(url);
    // }, function(error){
    // console.log(error);
    // });
  };

  openImage = (imageName) => {
    console.log("imageName", imageName);
    window.open(imageName);
  };
  render() {
    console.log(this.props, "props")

    // let unitsArr=this.state.units.filter(unit=> unit.data.belongsTo.id===this.props.selectedBuilding.params.id)
    let selectedBuildingTitle = this.props.selectedBuilding.params.slug
      .split("_")
      .join(" ");
    let unitsBelongingToSelectedBuilding = this.state.units.filter(
      (unit) => unit.data.belongsTo.id === this.props.selectedBuilding.params.id
    );
    console.log(
      "unitsBelongingToSelectedBuilding=",
      unitsBelongingToSelectedBuilding
    );
    console.log(
      unitsBelongingToSelectedBuilding.length,
      "the length of the array"
    );
    // otherwise, show the desired route
    if (!this.state.isFetched)
      return (
        <CSSTransition
          exit={true}
          in={true}
          appear={true}
          //set timeout being ignored
          timeout={1000}
          classNames="fade"
        >
          <Splash />
        </CSSTransition>
      );
    return (
      <>
        <div style={{ fontSize: "20px", padding: "12px" }} className="bg-gray-300 rounded shadow-lg bg-white p5 rounded-lg overflow-hidden sm:shadow-lg my-10">
          {this.props.desc}
        </div>
        <div data-tut="reactour__unrestrictedForm">
          <table className="rounded shadow-lg bg-white p5 sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-10">
            <thead>
              <tr>
                <td className="bg-gray-300" colSpan="5">
                  <span style={{ fontWeight: "600", color: "silver" }}>
                    {selectedBuildingTitle}
                  </span>{" "}
                  has the following units currently available for lease
                </td>
              </tr>
              <tr className="text-white bg-teal-400  wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                <td>Name</td>
                <td>
                  Size <sup>sqft</sup>
                </td>
                <td>
                  Price <sup>month</sup>
                </td>
                <td data-tut="reactour__unrestrictedPlans">Floorplan</td>
              </tr>
            </thead>
            <tbody>
              {this.state.units.filter(
                (unit) =>
                  unit.data.belongsTo.id ===
                  this.props.selectedBuilding.params.id &&
                  !unit.data.isWholeBuilding
              ).length === 0 && (
                  <tr className="empty-unit-row">
                    <td colSpan="12">
                      This building currently does not have any commercial units
                      for lease. Select another building using the dropdown above
                  </td>
                  </tr>
                )}

              {this.state.units
                .filter(
                  (unit) =>
                    unit.data.belongsTo.id ===
                    this.props.selectedBuilding.params.id &&
                    !unit.data.isWholeBuilding
                )
                .map((unit) => {
                  return (
                    <tr key={unit.id}>
                      <td>{unit.data.name}</td>
                      <td>{unit.data.size}</td>
                      <td>$ {unit.data.price}</td>
                      <td>
                        {/* <button onClick={() => this.downloadImage(unit.data.fileUrl)}><MdOpenInNew style={{fontSize:"18px"}}/></button> */}
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={() => this.openImage(unit.data.fileUrl)}
                        >
                          {/* <i className="material-icons">
            open_in_new
            </i> */}
                          <GrDocumentPdf style={{ fontSize: "xx-large" }} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <table className="rounded shadow-lg bg-white p5 sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-10">
            <thead>
              <tr>
                <td colSpan="5" className="bg-gray-300">
                  <span style={{ fontWeight: "600", color: "silver" }}>
                    {selectedBuildingTitle}
                  </span>{" "}
                  these plans represent the entire building layout
                </td>
              </tr>
              <tr className="bg-teal-400  wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 text-white">
                <td>Name</td>
                <td>
                  Size <sup>sqft</sup>
                </td>
                <td data-tut="reactour__unrestrictedPlans">Floorplan</td>
              </tr>
            </thead>
            <tbody>
              {this.state.units.filter(
                (unit) =>
                  unit.data.belongsTo.id ===
                  this.props.selectedBuilding.params.id &&
                  unit.data.isWholeBuilding
              ).length === 0 && (
                  <tr className="empty-unit-row" style={{ fontStyle: "italic" }}>
                    <td colSpan="12">
                      No complete building plans listed at this time, although you
                      can still see individual unit plans that are available in
                      the table above
                  </td>
                  </tr>
                )}

              {this.state.units
                .filter(
                  (unit) =>
                    unit.data.belongsTo.id ===
                    this.props.selectedBuilding.params.id &&
                    unit.data.isWholeBuilding
                )
                .map((unit) => {
                  return (
                    <tr key={unit.id}>
                      <td>{unit.data.name}</td>
                      <td>{unit.data.size}</td>
                      <td>
                        {/* <button onClick={() => this.downloadImage(unit.data.fileUrl)}><MdOpenInNew style={{fontSize:"18px"}}/></button> */}
                        <button
                          style={{ cursor: "pointer" }}
                          onClick={() => this.openImage(unit.data.fileUrl)}
                        >
                          {/* <i className="material-icons">
            open_in_new
            </i> */}
                          <GrDocumentPdf style={{ fontSize: "xx-large" }} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <Footer />
        </div>
      </>
    );
  }
}

export default UnprotectedTable;
