import React, { Component } from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { PayPalButton } from "react-paypal-button-v2";
import { getDateString, get30DaysFromNow } from "../utils";
const days = get30DaysFromNow();

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      payment: null,
      failedCount: 0,
    };

    this.onCloseDialog = this.onCloseDialog.bind(this);
  }

  componentDidMount() {}
  onCloseDialog() {
    this.setState({
      dialogOpen: false,
    });
    console.log("success dialog closed");
    this.props.history.push("/");
  }

  // onSuccess = response => {
  //   console.log("Successful payment!", response);
  //   this.setState({
  //     payment: response,
  //     dialogOpen: true
  //   });
  //   this.sendToServer();

  // };

  onError = (error) => {
    // console.log("Erroneous payment OR failed to load script!", error);
    console.log(error, "CheckoutForm Error");
    alert("Erroneous payment OR failed to load script!");
  };

  onCancel = (data) => {
    // console.log("Cancelled payment!", data);
    alert("Cancelled payment!");
  };

  // createOrder= (data, actions) => actions.order.create({
  //   application_context: {
  //     shipping_preference: 'NO_SHIPPING',
  //    },
  //   })
  sendSms = async (phone) => {
    console.log("inside of sendSms the phone number being passed is: ", phone);
    const body = { phone };
    try {
      // const res = await fetch("https://us-central1-firemap-28d9b.cloudfunctions.net/sendSms",{
      await fetch(
        "https://us-central1-firemap-28d9b.cloudfunctions.net/sendSms",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  sendEmail = async (clientObject) => {
    try {
      const res = await fetch(
        "https://us-central1-firemap-28d9b.cloudfunctions.net/sendMail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(clientObject),
        }
      );

      const message = await res.text();
      console.log(message); // print data missing
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    console.log(this.props, "this.props of the checkoutForm");
    // console.log(
    //   "destination lat :",
    //   this.props.directions.request.destination.location.lat()
    // );
    // console.log(
    //   "destination lng :",
    //   this.props.directions.request.destination.location.lng()
    // );
    // console.log(
    //   "origin lat :",
    //   this.props.directions.request.origin.location.lat()
    // );
    // console.log(
    //   "origin lng :",
    //   this.props.directions.request.origin.location.lng()
    // );

    this.props.selectedDay
      ? console.log(
          "inside of price Preview the users Chosen appointment date is:" +
            getDateString(days[this.props.selectedDay])
        )
      : console.log("There is no such SELECTED DAY PROP");

    const { price } = this.props;
    const { dialogOpen, payment } = this.state;
    const paypalOptions = {
      clientId:
        // process.env.NODE_ENV === "development"
        //   ? process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX
        //   : process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION,
        "ATpoWBr2Av7DcnFOkbB9kojkqGpwOi4I2cUL9p-fdziaeoYPpaxdW9s8vX2RwkD8O4MJdyXTV7Uq9KST",
      currency: "USD",
    };

    return (
      <div>
        <div className="checkout">
          <div className="price">
            <div className="label">Price:</div>
            <div className="value">${price}</div>
            <div id="application-fee">
              *leasing application fee applied towards credit check
            </div>
          </div>
          <div style={{ marginTop: 0 }}>
            <PayPalButton
              // disableShipping={true}
              createOrder={(data, actions) =>
                actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: "USD",
                        value: "25.00",
                      },
                    },
                  ],
                  application_context: {
                    shipping_preference: "NO_SHIPPING",
                  },
                })
              }
              options={paypalOptions}
              amount={price}
              onApprove={(data, actions) => {
                return actions.order.get().then((details) => {
                  console.log("order details =", details);
                  console.log("the payer", details.payer);
                  alert(
                    "Transaction completed by " + details.payer.name.given_name
                  );
                  this.setState({ payment: details, dialogOpen: true });
                  console.log("Successful payment!", details);
                  console.log("the data", data);
                  // this.sendEmail(details.payer, [
                  //   getDateString(days[this.props.selectedDay]),
                  //   this.props.selectedTime,
                  // ]);

                  console.log(
                    "the buildingAddress",
                    this.props.destinationBuilding
                  );
                  console.log(
                    "the selected warehouse/office unit:",
                    this.props.selectedUnit
                  );
                  console.log("the fileUrl prop", this.props.planUrl);

                  let origin;
                  let destination;

                  console.log(
                    "the props of CheckoutFOrm onApprove : ",
                    this.props
                  );

                  this.props.directions
                    ? (origin = [
                        this.props.directions.request.origin.location
                          .lat()
                          .toString(),
                        this.props.directions.request.origin.location
                          .lng()
                          .toString(),
                      ])
                    : (origin = [
                        this.props.userLatLong.lat,
                        this.props.userLatLong.lng,
                      ]);

                  this.props.directions
                    ? (destination = [
                        this.props.directions.request.destination.location
                          .lat()
                          .toString(),
                        this.props.directions.request.destination.location
                          .lng()
                          .toString(),
                      ])
                    : (destination = [
                        this.props.buildings[this.props.selectedBuildingIndex]
                          .data.location._lat,
                        this.props.buildings[this.props.selectedBuildingIndex]
                          .data.location._long,
                      ]);
                  console.log("the origin :", origin);
                  console.log("the destination :", destination);

                  // this.sendEmail(
                  //   [getDateString(days[this.props.selectedDay]), this.props.selectedTime],
                  //   //  ["2020-06-15", "10"],
                  //   details.payer.name.given_name,
                  //   details.payer.name.surname,
                  //   details.payer.email_address,
                  //   details.payer.payer_id,
                  //   details.payer.phone.phone_number.national_number,
                  //   details.payer.phone.phone_type,
                  //   origin,
                  //   destination,
                  //   this.props.destinationBuilding,
                  // );

                  let timeArray = [
                    getDateString(days[this.props.selectedDay]),
                    this.props.selectedTime,
                  ];
                  let clientObject = {};
                  clientObject.meetingDate = timeArray;
                  clientObject.given_name = details.payer.name.given_name;
                  clientObject.surname = details.payer.name.surname;
                  clientObject.email_address = details.payer.email_address;
                  clientObject.payer_id = details.payer.payer_id;
                  clientObject.phone =
                    details.payer.phone.phone_number.national_number;
                  clientObject.phone_type = details.payer.phone.phone_type;
                  clientObject.origin = origin;
                  clientObject.destination = destination;
                  clientObject.buildingAddress = this.props.destinationBuilding;
                  clientObject.planUrl = this.props.planUrl;
                  clientObject.selectedUnit = this.props.selectedUnit;
                  clientObject.notificationImage = this.props.buildings[
                    this.props.selectedBuildingIndex
                  ].data.fileUrl;

                  function convert2epoch(meetingDate) {
                    const apt = new Date(meetingDate[0]);
                    apt.setHours(apt.getHours() + Number(meetingDate[1]) + 8);
                    return apt.toLocaleString().replace(/(.*)\D\d+/, "$1");
                  }
                  let meetingDateString = convert2epoch(timeArray);
                  clientObject.meetingDateString = meetingDateString;

                  console.log(
                    "the chosen time as string is :",
                    meetingDateString
                  );

                  this.sendEmail(clientObject);
                  console.log("here you call sendmail with the new stuff");
                });
              }}
              // onSuccess={(details, data) => {
              //   alert("Transaction completed by " + details.payer.name.given_name);

              //   // OPTIONAL: Call your server to save the transaction
              //   // return fetch("/paypal-transaction-complete", {
              //   //   method: "post",
              //   //   body: JSON.stringify({
              //   //     orderID: data.orderID
              //   //   })
              //   // });
              //   this.setState({payment:details,dialogOpen:true})
              //   console.log("Successful payment!", details);
              //   console.log('the data', data)
              //   // this.sendEmail(details.payer.email_address, getDateString(days[this.props.selectedDay]))
              //   // .then(console.log('email was sent out'))
              //   // .then(this.sendSms(details.payer.phone.phone_number.national_number))
              //   // .then(console.log('sms was sent to :,', details.payer.phone.phone_number.national_number))
              //   this.sendEmail(details.payer, [getDateString(days[this.props.selectedDay]), this.props.selectedTime])

              // }}

              onCancel={this.onCancel}
              onError={this.onError}
            />
          </div>
        </div>
        <Dialog
          open={dialogOpen}
          onClose={this.onCloseDialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{"Booking confirmed"}</DialogTitle>
          <DialogContent>
            {payment ? (
              <div style={{ marginBottom: 20 }}>
                <div
                  style={{
                    marginBottom: 10,
                    color: "var(--colorMain)",
                    fontSize: 22,
                    fontWeight: 600,
                  }}
                >
                  Price : ${price}
                </div>
                <div>
                  Created at: {new Date(payment.create_time).toDateString()}
                </div>
                <div>
                  Name:{" "}
                  {`${payment.payer.name.given_name} ${payment.payer.name.surname}`}
                </div>
                <div>Email: {payment.payer.email_address}</div>
                <div>
                  Info: We've sent an mail with directions to your selected
                  building as well as the spaceplan of your selected unit, check
                  your spam folder if you've not recieved it.
                </div>
              </div>
            ) : (
              <div />
            )}
            <Button onClick={this.onCloseDialog} color="primary">
              OK
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default CheckoutForm;
