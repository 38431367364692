export const Styles = {
  placeHolder: {
    backgroundColor: "white",
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `250px`,
    height: `42px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `16px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    left: "50%",
    marginLeft: "-120px",
    marginTop: "0px",
  },
  submitBuilding: {
    backgroundColor: "green",
    color: "white",
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `100px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    left: "73%",
    top: 0,
    cursor: "pointer",
  },
};

export default Styles;
