import React, { PureComponent } from "react";
// import './materialize.css'
import "./FooterStyles.css";
import "./tailwindmin.css";

import ProgressiveImage from "react-progressive-image-loading";
import { AiOutlineMail } from "react-icons/ai";
class Footer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="footer-container" style={this.props.style}>
        <div className="row">
          <div className="col l8 m5 s5">
            <div
              style={{
                width: "75px",
              }}
            >
              <ProgressiveImage
                preview={process.env.PUBLIC_URL + "/assets/img/broker_blur.jpg"}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/industrial_leasing_real_estate_broker.jpg"
                }
                transitionTime={6000}
                transitionFunction="ease"
                render={(src, style) => <img src={src} alt="" style={style} />}
              />
            </div>

            {/* <img src="https://www.theportlandclinic.com/wp-content/uploads/2019/07/Person-Curtis_4x5-e1564616444404-300x300.jpg" width="74px"/> */}

            <h5
              style={{
                color: "white",
              }}
              className="name-head-desktop"
            >
              Lease Warehouses
            </h5>
          </div>
          <div className="col l4 m7 s7 mh-sec" style={{ padding: "11px 10px" }}>
            <span className="contact-details">
              <a
                href="tel:8479820340"
                className="p-num"
                style={{ fontWeight: "bold" }}
              >
                847-982-0340
              </a>
              <h6 className="name-head-mob" style={{ display: "none" }}>
                Leasing
              </h6>
            </span>

            <a
              href="mailto:leasewarehouses@gmail.com?cc=bookkeeping@greystoneassociates.net, bschur@greystoneassociates.net&subject=Industrial%20Leasing&body=Reguarding warehouses and industrial spaces and listings for lease on your website"
              data-tut="reactour__contact"
              className="c-btn"
              style={{ float: "right" }}
            >
              <AiOutlineMail
                style={{
                  fontSize: "x-large",
                  display: "inline-block",
                  marginRight: "2px",
                  paddingBottom: "2px",
                }}
              />
              <span style={{}}>email</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
