export const DirectionStyles = {
  hiddenStyle: {
    display: "none",
  },
  // scheduleBtnStyle: {
  //   backgroundColor: "white",
  //   position: "relative",
  //   top: "-36px",
  //   left: "67px",
  //   fontWeight: "400",
  //   width: "fit-content",
  //   fontSize: "16px",
  //   opacity: ".9",
  //   color: "#676768",
  //   borderRadius: "2px",
  //   display: "table-cell",
  //   textAlign: "center",
  //   boxSizing: "border-box",
  //   padding: "0px 18px",
  //   boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px ",
  // },
  scheduleBtnStyle: {
    direction: "ltr",
    overflow: "hidden",
    textAlign: "center",
    height: "40px",
    display: "table-cell",
    verticalAlign: "middle",
    position: "absolute",
    top: "74px",
    left: "60px",
    color: "rgb(0, 0, 0)",
    // fontFamily: "Roboto, Arial, sans-serif",
    userSelect: "none",
    fontSize: "18px",
    backgroundColor: "rgb(255, 255, 255)",
    padding: "0px 23px",
    borderRadius: "2px",
    backgroundClip: "padding-box",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
    minWidth: "36px",
    fontWeight: "500",
    opacity: 0.9,
    lineHeight: 2.2,
  },

  infoPanelStyle: {
    position: "absolute",
    top: "135px",
    width: "50%",
    height: "65%",
    backgroundColor: "rgb(238,238,238)",
    marginLeft: "15px",
    overflow: "auto",
    bordeRadius: "3px",
    opacity: ".9",
    boxShadow: "0px 3px 8px rgb(100,100,100)",
    direction: "ltr",
  },
  XbuttonDiv: {
    left: "7px",
    position: "absolute",
    top: "127px",
    backgroundColor: "#61beb5",
    width: "30px",
    height: "30px",
    fontSize: "24px",
    borderTopLeftRadius: "2px",
    borderTopRightRadius: "2px",
  },
  LinkStyle: {
    marginLeft: "7px",
    position: "relative",
    color: "black",
    bottom: "2px",
  },
};
