import React, { useState } from "react";
import { get30DaysFromNow, getTimeString } from "../utils";
import { Grid, Box, Container, Button } from "@material-ui/core";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link } from "react-router-dom";

// ./src/components/Calendar/TimeSelect.js
//   Line 8:16:  'setDay' is assigned a value but never used   no-unused-vars
//   Line 9:17:  'setTime' is assigned a value but never used  no-unused-vars
const TimeSelect = (props) => {
  // const [days, setDay] = useState(get30DaysFromNow());
  // const [times, setTime] = useState([
  const [days] = useState(get30DaysFromNow());
  const [times] = useState([
    {
      from: 8,
      to: 9,
    },
    {
      from: 9,
      to: 10,
    },
    {
      from: 10,
      to: 11,
    },
    {
      from: 11,
      to: 12,
    },
    {
      from: 12,
      to: 13,
    },
    {
      from: 13,
      to: 14,
    },
    {
      from: 14,
      to: 15,
    },
    {
      from: 15,
      to: 16,
    },
    {
      from: 16,
      to: 17,
    },
    {
      from: 17,
      to: 18,
    },
    {
      from: 18,
      to: 19,
    },
    {
      from: 19,
      to: 20,
    },
  ]);
  const [showDays, setShowDay] = useState([
    days[0],
    days[1],
    days[2],
    days[3],
    days[4],
  ]);
  const [moreOrLess, setMoreOrLess] = useState(true);
  const [selectedDayIndex, setSelectedDayIndex] = useState();
  const [selectedTimeIndex, setSelectedTimeIndex] = useState();
  function onClickMore() {
    setShowDay(days);
    setMoreOrLess(false);
  }
  function onClickLess() {
    let showDays = [days[0], days[1], days[2], days[3], days[4]];
    setShowDay(showDays);
    setMoreOrLess(true);
  }
  function onClickContinue(e) {
    if (selectedDayIndex === 0) {
      alert(
        "Management does not do last minute unit showings, book a viewing tomorrow or another day."
      );
      e.preventDefault();
      return;
    }
    if (selectedDayIndex === undefined || selectedTimeIndex === undefined) {
      alert("You have to select a day and a time");
      e.preventDefault();
      return;
    } else {
      props.selectedTime(selectedTimeIndex);
      props.selectedDay(selectedDayIndex);
    }
  }
  return (
    <div>
      <Container
        maxWidth="lg"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        <Box p={1} textAlign="left" fontSize={24}>
          What day will you come see the space?
        </Box>
        <Box>
          <Grid container>
            {showDays.map((date, i) => (
              <Grid key={i} item sm={2} xs={3}>
                <div
                  className={`button date ${
                    selectedDayIndex === i ? "selected" : ""
                  }`}
                  onClick={() => setSelectedDayIndex(i)}
                >
                  <div
                    className={date.firstDay === true ? "first-day" : "weekday"}
                  >
                    {date.weekDay}
                  </div>
                  <div className="day">{date.day}</div>
                </div>
              </Grid>
            ))}
            <Grid item sm={2} xs={3}>
              {moreOrLess ? (
                <div className="button date more-less" onClick={onClickMore}>
                  <div>More</div>
                  <div>
                    <IoIosArrowDown />
                  </div>
                </div>
              ) : (
                <div className="button date more-less" onClick={onClickLess}>
                  <div>
                    <IoIosArrowUp />
                  </div>
                  <div>Less</div>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box p={1} mt={3} textAlign="left">
          <Box fontSize={18}>
            What time will you arrive at the building you'd like to see?
          </Box>
          <Box fontSize={14}>
            The app will send you notifications reguarding your booking, the
            leasing agent will meet you in front of your chosen building
          </Box>
        </Box>
        <Box py={1}>
          <Grid container>
            {times.map((time, i) => (
              <Grid key={i} item sm={4} xs={6}>
                <div
                  className={`button time ${
                    selectedTimeIndex === i ? "selected" : ""
                  }`}
                  onClick={() => setSelectedTimeIndex(i)}
                  style={{ margin: 4 }}
                >
                  {getTimeString(time.from)} - {getTimeString(time.to)}
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
        <div style={{ margin: 4 }}>
          <Link to={`/pricepreview`}>
            <Button fullWidth className="bule-btn" onClick={onClickContinue}>
              Continue
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};
export default TimeSelect;
